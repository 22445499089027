import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Loading from './Pages/Loading';
import Login from './Pages/Login';
import { AuthProvider } from './Utils/auth-context';

const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        <AuthProvider>
          <Route exact path="/login" component={Login} />
          <Route exact path={['/', '/product', '/member',
        '/member', '/member/edit/:id', '/member/password/:id', '/kind', '/job', '/job/edit/:id',
        '/brand', '/brand/edit/:id', '/brand/image/:id', '/country', '/country/edit/:id',
        '/customer', '/customer/edit/:id', '/customer/password/:id', '/work', '/work/edit/:id',
        '/category', '/category/edit/:id', '/kind','/kind/edit/:id', '/update']} component={Loading} />
        </AuthProvider>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
