import React, { useRef, useState, KeyboardEvent, ChangeEvent, useEffect } from 'react';
import Swal from 'sweetalert2';
import { API_Add_Country } from '../../Utils/api';

interface ItemStruct {
    name_country: string
}

interface Struct {
    refresh: () => void
}

const Country_Add = (props : Struct) => {

    const [Toggle, setToggle] = useState(false);

    const Data = useRef<ItemStruct>({
        name_country: '',
    });

    const [Error, setError] = useState({
        name_country: false,
    });

    const onEnter = (event : KeyboardEvent<HTMLInputElement>) : void => {
        event.key === 'Enter' && onSubmitData()
    }

    const onEsc = () : void => {
        document.onkeydown = (event) => {
            event.keyCode === 27 && setToggle(false);
        }
    }

    const onSubmitData = () : void => {
        if (
            CheckEmpty(Data.current.name_country, 'name_country')
        ) {
            Swal.fire({
                icon: 'warning',
                title: 'คำเตือน',
                text: 'คุณต้องการที่จะเพิ่มประเทศ ใช่ หรือ ไม่?',
                timer: 5000,
                timerProgressBar: true,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'ใช่',
                cancelButtonText: 'ไม่',
            }).then(result => {
                if (result.value) {
                    PostData();
                }
            });
        }
    }

    const CheckEmpty = (value: string, id : string) : boolean => {
        if (value !== '') {
            setError(preState => ({
                ...preState,
                [id]: false
            }));
            return true;
        } else {
            document.getElementById(id)?.focus();
            setError(preState => ({
                ...preState,
                [id]: true
            }));
            return false;
        }
    }

    const PostData = () : void => {
        API_Add_Country(Data.current).then(res => {
            Swal.fire({
                icon: res.status ? 'success' : 'error',
                title: res.status ? 'สำเสร็จ' : 'เกิดข้อผิดพลาด',
                text: res.message,
                timer: 5000,
                timerProgressBar: true,
            }).then(() => {
                if (res.status) {
                    props.refresh();
                    ClearInput();
                }
            });
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด',
                text: 'ไม่สามารถเชื่อมต่อฐานข้อมูลได้',
                timer: 5000,
                timerProgressBar: true,
            });
        });
    }

    const ClearInput = () => {
        Data.current = {
            name_country: ''
        }

        document.querySelectorAll('input').forEach((item) => {
            item.value = '';
        });
    }

    const onChangeData = (event : ChangeEvent<HTMLInputElement>) : void => {
        let data = {
            ...Data.current,
            [event.target.id]: event.target.value
        }
        Data.current = data;
    }

    useEffect(() => {
        onEsc();
    }, []);

    return (
        <>
        <button onClick={() => setToggle(!Toggle)} className="button is-link">เพิ่มประเทศ</button>
        <div className={`modal ${Toggle ? 'is-active' : ''}`}>
            <div onClick={() => setToggle(!Toggle)} className="modal-background" />
            <div className="modal-card">
                <header className="modal-card-head">
                <p className="modal-card-title">เพิ่มประเทศ</p>
                <button onClick={() => setToggle(!Toggle)} className="delete" aria-label="close" />
                </header>
                <section className="modal-card-body">
                    <div className="field">
                        <label className="label">ชื่อประเทศ</label>
                        <div className="control">
                            <input onKeyPress={onEnter} onChange={onChangeData} 
                            autoFocus className={`input ${Error.name_country ? 'is-danger' : ''}`} type="text" id="name_country" placeholder="ประเทศ" />
                        </div>
                    </div>

                    <button onClick={() => onSubmitData()} className="button is-link is-fullwidth">เพิ่มประเทศ</button>
                </section>
            </div>
        </div>

        </>
    );
}

export default Country_Add;