import React, { useRef, useState, KeyboardEvent, ChangeEvent, useEffect } from 'react';
import Swal from 'sweetalert2';
import { API_Add_Update } from '../../Utils/api';

interface ItemStruct {
    version: string
    detail: string
    file_update: File | undefined
}

interface Item {
    refresh?: () => void
}

const Update_Add = (props : Item) => {

    const [Toggle, setToggle] = useState(false);

    const Data = useRef<ItemStruct>({
        version: '',
        detail: '',
        file_update: undefined,
    });

    const [Error, setError] = useState({
        version: false,
        detail: false,
        file_update: false,
    });

    const onEnter = (event : KeyboardEvent<HTMLInputElement>) : void => {
        event.key === 'Enter' && onSubmitData()
    }

    const onSubmitData = () : void => {
        if (
            CheckEmpty(Data.current.version, 'version') &&
            CheckEmpty(Data.current.detail, 'detail') &&
            CheckFile(Data.current.file_update, 'file_update')
        ) {
            Swal.fire({
                icon: 'warning',
                title: 'คำเตือน',
                text: 'คุณต้องการที่จะเพิ่มสมาชิก ใช่ หรือ ไม่?',
                timer: 5000,
                timerProgressBar: true,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'ใช่',
                cancelButtonText: 'ไม่',
            }).then(result => {
                if (result.value) {
                    API_Post();
                }
            });
        }
    }

    const CheckFile = (value : File | undefined, id : string) => {
        if (value !== undefined) {
            setError(preState => ({
                ...preState,
                [id]: false
            }));
            return true;
        } else {
            document.getElementById(id)?.focus();
            setError(preState => ({
                ...preState,
                [id]: true
            }));
            return false;
        }
    }

    const CheckEmpty = (value: string, id : string) : boolean => {
        if (value !== '') {
            setError(preState => ({
                ...preState,
                [id]: false
            }));
            return true;
        } else {
            document.getElementById(id)?.focus();
            setError(preState => ({
                ...preState,
                [id]: true
            }));
            return false;
        }
    }

    const ClearInput = () => {
        Data.current = {
            version: '',
            detail: '',
            file_update: undefined,
        }

        document.querySelectorAll('input').forEach((item) => {
            item.value = '';
        });

    }

    const API_Post = () => {
        API_Add_Update(Data.current).then(res => {
            Swal.fire({
                icon: res.status ? 'success' : 'error',
                title: res.status ? 'สำเสร็จ' : 'เกิดข้อผิดพลาด',
                text: res.message,
                timer: 5000,
                timerProgressBar: true,
            }).then(() => {
                if (res.status) {
                    ClearInput();
                }
            });
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด',
                text: 'ไม่สามารถเชื่อมต่อฐานข้อมูลได้',
                timer: 5000,
                timerProgressBar: true,
            });
        });
    }

    const onChangeData = (event : ChangeEvent<any>) : void => {
        let data : ItemStruct;
        if (event.target.files) {
            data = {
                    ...Data.current,
                    file_update: event.target.files[0],
                }
        } else {
            data = {
                    ...Data.current,
                    [event.target.id]: event.target.value
                }
        }
        Data.current = data;
    }


    const onEsc = () : void => {
        document.onkeydown = (event) => {
            event.keyCode === 27 && setToggle(false);
        }
    }

    useEffect(() => {
        onEsc();
    }, []);

    return (
        <>
        <button onClick={() => setToggle(!Toggle)} className="button is-link">เพิ่มการอัพเดท</button>
        <div className={`modal ${Toggle ? 'is-active' : ''}`}>
            <div onClick={() => setToggle(!Toggle)} className="modal-background" />
            <div className="modal-card">
                <header className="modal-card-head">
                <p className="modal-card-title">เพิ่มข้อมูลการอัพเดท</p>
                <button onClick={() => setToggle(!Toggle)} className="delete" aria-label="close" />
                </header>
                <section className="modal-card-body">
                    <div className="field">
                        <label htmlFor="version" className="label">เวอร์ชั่น</label>
                        <div className="control">
                            <input onKeyPress={onEnter} onChange={onChangeData} 
                            autoFocus className={`input ${Error.version ? 'is-danger' : ''}`} type="text" id="version" placeholder="เวอร์ชั่นล่าสุด" />
                        </div>
                    </div>
                    <div className="field">
                        <label htmlFor="detail" className="label">รายละเอียด</label>
                        <div className="control">
                            <textarea id="detail" onChange={onChangeData} className={`textarea ${Error.detail ? 'is-danger' : ''}`} cols={3} rows={10}></textarea>
                        </div>
                    </div>
                    <div className="field">
                        <label htmlFor="file_update" className="label">ไฟส์อัพเดท</label>
                        <div className="control">
                            <input onKeyPress={onEnter} onChange={onChangeData} 
                            className={`input ${Error.file_update ? 'is-danger' : ''}`} type="file" id="file_update" />
                        </div>
                    </div>

                    <button onClick={() => onSubmitData()} className="button is-link is-fullwidth">เพิ่มการอัพเดท</button>
                </section>
            </div>
        </div>

        </>
    );
}

export default Update_Add;