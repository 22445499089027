import React, { useEffect } from "react";
import { useState } from "react";
import { FaBox, FaDownload, FaUser, FaUserTie } from "react-icons/fa";
import Menu from "../Components/Menu";
import { API_Report_Header } from "../Utils/api";

interface HeaderStruct {
  member: number
  product: number
  brand: number
  download: number
}

const Home = () => {

  const [Header, setHeader] = useState<HeaderStruct>();

  const GetHeader = () => {
    API_Report_Header().then(res => {
      console.log(res)
      setHeader(res.data.data);
    })
  }

  useEffect(() => {
    GetHeader();
  }, []);

  const data = {
  labels: ['1', '2', '3', '4', '5', '6'],
  datasets: [
    {
      label: '# of Votes',
      data: [12, 19, 3, 5, 2, 3],
      fill: false,
      backgroundColor: 'rgb(255, 99, 132)',
      borderColor: 'rgba(255, 99, 132, 0.2)',
    },
  ],
};

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

  return (
    <div className="is-flex" style={{ gap: "10px" }}>
      <Menu />
      <div className="p-2 container">
        <div className="columns">
          <div className="column">
            <div className="card">
              <div className="card-content is-flex is-justify-content-space-between is-align-items-center">
                <div>
                สมาชิกทั้งหมด
                  <b className="is-block">{Header?.member}</b>
                </div>
                <FaUser size="50" />
              </div>
            </div>
          </div>
          <div className="column">
            <div className="card">
              <div className="card-content is-flex is-justify-content-space-between is-align-items-center">
                <div>
                  สินค้าทั้งหมด
                  <b className="is-block">{Header?.product}</b>
                </div>
                <FaBox size="50" />
              </div>
            </div>
          </div>
          <div className="column">
            <div className="card">
              <div className="card-content is-flex is-justify-content-space-between is-align-items-center">
                <div>
                แบรนด์ทั้งหมด
                  <b className="is-block">{Header?.brand}</b>
                </div>
                <FaUserTie size="50" />
              </div>
            </div>
          </div>
          <div className="column">
            <div className="card">
              <div className="card-content is-flex is-justify-content-space-between is-align-items-center">
                <div>
                ดาวน์โหลดทั้งหมด
                  <b className="is-block">{Header?.download}</b>
                </div>
                <FaDownload size="50" />
              </div>
            </div>
          </div>
        </div>

        <div className="columns">
          <div className="column">
            <div className="card">
              <div className="card-content">
                กราฟรายงาน
              </div>
            </div>
          </div>
        </div>

        <div className="columns">
          <div className="column">
            <div className="card">
              <div className="card-content">สินค้ายอดนิยม</div>
            </div>
          </div>
          <div className="column">
            <div className="card">
              <div className="card-content">ประเทศ</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
