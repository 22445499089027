import React from 'react';
import Menu from '../../Components/Menu';

const Product_Page = () => {
    return (
        <>
        <Menu />
        </>
    );
}

export default Product_Page; 