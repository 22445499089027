import React, { useEffect, useState } from 'react';
import { BsClockFill, BsPencil, BsTrashFill } from 'react-icons/bs';
import { useHistory } from 'react-router';
import ReactTooltip from 'react-tooltip';
import Swal from 'sweetalert2';
import KindAdd from '../../Components/Kind/add';
import Menu from '../../Components/Menu';
import { API_Get_Kind, API_Remove_Kind } from '../../Utils/api';

interface ItemStruct {
    id_kind: string
    name_category: string
    name_kind: string
    created_at: string
    updated_at: string
}

const Kind_Page = () => {

    const history = useHistory();

    const [Data, setData] = useState<Array<ItemStruct>>([]);

    const Remove = (id : string) : void => {
        Swal.fire({
            icon: 'warning',
            title: 'คำเตือน',
            text: 'คุณต้องการที่จะลบ ใช่ หรือ ไม่?',
            timer: 5000,
            timerProgressBar: true,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'ใช่',
            cancelButtonText: 'ไม่',
        }).then(result => {
            if (result.value) {
                Remove_Data(id);
            }
        });
    }

    const Remove_Data = (id : string) => {
        API_Remove_Kind(id).then((res => {
            Swal.fire({
                icon: res.status ? 'success' : 'error',
                title: res.status ? 'สำเสร็จ' : 'เกิดข้อผิดพลาด',
                text: res.message,
                timer: 5000,
                timerProgressBar: true,
            });
            if (res.status) {
                Get_Data();
            }
        })).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด',
                text: 'ไม่สามารถเชื่อมต่อฐานข้อมูลได้',
                timer: 5000,
                timerProgressBar: true,
            });
        });
    }

    const Get_Data = () : void => {
        API_Get_Kind('').then(res => {
            setData(res.data);
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด',
                text: 'ไม่สามารถเชื่อมต่อฐานข้อมูลได้',
                timer: 5000,
                timerProgressBar: true,
            });
        });
    }

    useEffect(() => {
        Get_Data();
    }, []);

    return (
        <div className="is-flex" style={{gap: '10px'}}>
            <Menu />
            <div className="p-2 container">
                <h5 className="subtitle is-5 mt-3 mb-3">จัดการกับหมวดงาน</h5>
                <div className="card">
                    <div className="card-content">
                        <KindAdd refresh={Get_Data} />
                    </div>
                </div>

                <div className="card mt-2">
                    <div className="card-content">
                        <table className="table is-hoverable is-striped is-fullwidth">
                            <thead>
                                <tr>
                                    <th>ลำดับ</th>
                                    <th>ชื่อประเภท</th>
                                    <th>ชื่อหมวดหมู่</th>
                                    <th>เครื่องมือ</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    Data.length > 0 &&
                                    Data.map((item, index ) => (
                                        <tr key={index}>
                                            <td>{item.id_kind}</td>
                                            <td>{item.name_category}</td>
                                            <td>{item.name_kind}</td>
                                            <td>
                                                <button data-tip={`สร้างเมื่อ [${item.created_at}] แก้ไขเมื่อ [${item.updated_at}]`} data-for="data-country" className="button is-small is-link mr-2"><BsClockFill /></button>
                                                <button onClick={() => history.push(`/Kind/edit/${item.id_kind}`) } data-tip="แก้ไขข้อมูล" data-for="data-country" className="button is-small is-warning mr-2"><BsPencil /></button>
                                                <button onClick={() => Remove(item.id_kind)} data-tip="ลบข้อมูล" data-for="data-country" className="button is-small is-danger"><BsTrashFill /></button>
                                                <ReactTooltip id="data-country" place="bottom" effect="solid" />
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Kind_Page; 