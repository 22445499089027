import axios from "axios";

const API = "https://api.product-connect.com/api/v2/";
// const API = "http://139.59.98.79:5420/api/v2/";
//  const API = "http://localhost:5421/api/v2/";

axios.interceptors.request.use((request) => {
    request.headers.Authorization = `Bearer ${window.localStorage.getItem('token')}` 
    return request;
}, (err) => {
    return Promise.reject(err.response);
})

axios.interceptors.response.use((response) => {
    return response;
}, (error) => {
    if(error.response.status === 401) {
        if (error.response.data.message === 'Token is expired') {
            if (localStorage.hasOwnProperty('refresh_token')) {
                axios.post(API + "auth/refresh", {}, {
                    headers: {
                        'Authorization': `Bearer ${window.localStorage.getItem("refresh_token")}`
                    }
                }).then(res => {
                    localStorage.setItem('token', res.data.token['access_token']);
                    localStorage.setItem('refresh_token', res.data.token['refresh_token']);
                }).catch(() => {
                    localStorage.removeItem('token');
                    localStorage.removeItem('refresh_token');
                    window.location.href = '/login';
                    return;
                });
            }
        }
    }
    return Promise.reject(error.response);
})

interface LoginStruct {
    username: string
    password: string
}

interface MemberStruct {
    id_member?: string
    username: string
    password?: string
    firstname: string
    lastname: string
    gender?: string
    id_job?: string
}

interface CustomerStruct {
    id_customer?: string
    id_brand: string
    username: string
    password?: string
}

interface WorkStruct {
    id_work?: string
    id_country: string
    name_work: string
}

interface CategoryStruct {
    id_category?: string
    id_work: string
    name_category: string
}

interface KindStruct {
    id_kind?: string
    id_category: string
    name_kind: string
}

interface JobStruct {
    id_job?: string
    name_job: string
}

interface CountryStruct {
    id_country?: string
    name_country: string
}

interface BrandStruct {
    id_brand?: string
    id_country: string
    name_brand: string
    website: string
    tel: string
    email: string
    address: string
    image?: File
}

interface UpdateStruct {
    version: string
    detail: string
    file_update: File | undefined
}

export const API_Get_Category = (id : string) => {
    if (id === "") {
        return axios.get(API + "category/").then(res => res.data).catch(err => err.response);
    } else {
        return axios.get(API + "category/search/" + id).then(res => res.data).catch(err => err.response);
    }
}

export const API_Get_Category_Work = (id : string) => {
    return axios.get(API + "category/work/" + id).then(res => res.data).catch(err => err.response);
}

export const API_Get_Update = () => {
    return axios.get(API + "update/").then(res => res.data).catch(err => err.response);
}

export const API_Get_Kind = (id : string) => {
    return axios.get(API + "kind/" + id).then(res => res.data).catch(err => err.response);
}

export const API_Get_Customer = (id : string) => {
    return axios.get(API + "customer/" + id).then(res => res.data).catch(err => err.response);
}

export const API_Get_Brand = (id : string) => {
    return axios.get(API + "brand/" + id).then(res => res.data).catch(err => err.response);
}

export const API_Get_Work = (id : string) => {
    return axios.get(API + "work/" + id).then(res => res.data).catch(err => err.response);
}

export const API_Get_Job = (id : string) => {
    return axios.get(API + "job/" + id).then(res => res.data).catch(err => err.response);
}

export const API_Remove_Job = (id : string) => {
    return axios.delete(API + "job/" + id).then(res => res.data).catch(err => err.response);
}

export const API_Remove_Category = (id : string) => {
    return axios.delete(API + "category/" + id).then(res => res.data).catch(err => err.response);
}

export const API_Remove_Brand = (id : string) => {
    return axios.delete(API + "brand/" + id).then(res => res.data).catch(err => err.response);
}

export const API_Remove_Work = (id : string) => {
    return axios.delete(API + "work/" + id).then(res => res.data).catch(err => err.response);
}

export const API_Remove_Kind = (id : string) => {
    return axios.delete(API + "kind/" + id).then(res => res.data).catch(err => err.response);
}

export const API_Remove_Customer = (id : string) => {
    return axios.delete(API + "customer/" + id).then(res => res.data).catch(err => err.response);
}

export const API_Remove_Member = (id : string) => {
    return axios.delete(API + "member/" + id).then(res => res.data).catch(err => err.response);
}

export const API_Remove_Update = (id : string) => {
    return axios.delete(API + "update/" + id).then(res => res.data).catch(err => err.response);
}

export const API_Update_Member = (data : MemberStruct) => {
    return axios.patch(API + "member/data/" + data.id_member, {
        username: data.username,
        firstname: data.firstname,
        lastname: data.lastname,
    }).then(res => res.data).catch(err => err.response);
}

export const API_Update_Job = (data : JobStruct) => {
    return axios.patch(API + "job/" + data.id_job, {
        name_job: data.name_job
    }).then(res => res.data).catch(err => err.response);
}

export const API_Update_Category = (data : CategoryStruct) => {
    return axios.patch(API + "category/" + data.id_category, {
        id_work: data.id_work.toString(),
        name_category: data.name_category,
    }).then(res => res.data).catch(err => err.response);
}

export const API_Update_Kind = (data : KindStruct) => {
    return axios.patch(API + "kind/" + data.id_kind, {
        id_category: data.id_category.toString(),
        name_kind: data.name_kind,
    }).then(res => res.data).catch(err => err.response);
}

export const API_Update_Work = (data : WorkStruct) => {
    return axios.patch(API + "work/" + data.id_work, {
        id_country: data.id_country.toString(),
        name_work: data.name_work
    }).then(res => res.data).catch(err => err.response);
}

export const API_Update_Brand = (data : BrandStruct) => {
    return axios.patch(API + "brand/data/" + data.id_brand, {
        name_brand: data.name_brand,
        website: data.website,
        tel: data.tel,
        email: data.email,
        address: data.address,
        id_country: data.id_country
        
    }).then(res => res.data).catch(err => err.response);
}

export const API_Update_Customer = (data : CustomerStruct) => {
    return axios.patch(API + "customer/data/" + data.id_customer, {
        username: data.username,
        id_brand: parseInt(data.id_brand),
        password: 'fakepassword',
    }).then(res => res.data).catch(err => err.response);
}

export const API_Add_Job = (data : JobStruct) => {
    return axios.post(API + "job/", {
        name_job: data.name_job
    }).then(res => res.data).catch(err => err.response);
}

export const API_Add_Category = (data : CategoryStruct) => {
    return axios.post(API + "category/", {
        id_work: data.id_work,
        name_category: data.name_category
    }).then(res => res.data).catch(err => err.response);
}

export const API_Add_Kind = (data : KindStruct) => {
    return axios.post(API + "kind/", {
        id_category: data.id_category,
        name_kind: data.name_kind
    }).then(res => res.data).catch(err => err.response);
}

export const API_Add_Work = (data : WorkStruct) => {
    return axios.post(API + "work/", {
        id_country: data.id_country,
        name_work: data.name_work
    }).then(res => res.data).catch(err => err.response);
}

export const API_Add_Country = (data : CountryStruct) => {
    return axios.post(API + "country/", {
        name_country: data.name_country
    }).then(res => res.data).catch(err => err.response);
}

export const API_Add_Member = (data : MemberStruct) => {
    return axios.post(API + "member/", {
        username: data.username,
        password: data.password,
        firstname: data.firstname,
        lastname: data.lastname,
        gender: data.gender === '1' ? true : false,
        id_job: parseInt(data.id_job !== undefined ? data.id_job : ""),
    }).then(res => res.data).catch(err => err.response);
}

export const API_Add_Customer = (data : CustomerStruct) => {
    return axios.post(API + "customer/", {
        username: data.username,
        password: data.password,
        id_brand: parseInt(data.id_brand),
    }).then(res => res.data).catch(err => err.response);
}

export const API_Add_Brand = (data : BrandStruct) => {
    const form = new FormData() 
    form.append('id_country', data.id_country);
    form.append('name_brand', data.name_brand);
    form.append('email', data.email);
    form.append('website', data.website);
    form.append('tel', data.tel);
    form.append('image_brand', data.image as File);
    form.append('address', data.address);
    return axios.post(API + "brand/", form).then(res => res.data).catch(err => err.response);
}

export const API_Image_Brand = (id : string, image : File) => {
    const form = new FormData() 
    form.append('image_brand', image);
    return axios.patch(API + "brand/img/" + id, form).then(res => res.data).catch(err => err.response);
}

export const API_Add_Update = (data : UpdateStruct) => {
    const form = new FormData() 
    form.append('version', data.version.toString());
    form.append('detail', data.detail.toString());
    data.file_update !== undefined && form.append('file_update', data.file_update);
    return axios.post(API + "update/", form).then(res => res.data).catch(err => err.response);
}

export const API_Get_Member = (id : string, page?: number, search? : string) => {
    return axios.get(API + "member/" + id + ("?page=" + page || "") + "&search=" + search || "").then(res => res.data).catch(err => err.response);
}

export const API_Get_Country = (id : string) => {
    return axios.get(API + "country/" + id).then(res => res.data).catch(err => err.response);
}

export const API_Update_Country = (data : CountryStruct) => {
    return axios.patch(API + "country/" + data.id_country, {
        name_country: data.name_country
    }).then(res => res.data).catch(err => err.response);
}

export const API_Password_Member = (id : string, password : string) => {
    return axios.patch(API + "member/pass/" + id, {
        password: password,
    }).then(res => res.data).catch(err => err.response);
}

export const API_Password_Customer = (id : string, password : string) => {
    return axios.patch(API + "customer/pass/" + id, {
        password: password,
    }).then(res => res.data).catch(err => err.response);
}

export const API_Remove_Country = (id : string) => {
    return axios.delete(API + "country/" + id).then(res => res.data).catch(err => err.response);
}

export const API_Login = (data : LoginStruct) => {
    return axios.post(API + "auth/login", {
        username: data.username,
        password: data.password
    }).then(res => res.data).catch(err => err.response);
}

export const API_Me = () => {
    return axios.post(API + "auth/me", {}).then(res => res).catch(err => err.response);
}

export const API_Logout = () => {
    return axios.post(API + "auth/logout", {}).then(res => res.data).catch(err => err.response);
}

export const API_Report_Header = () => {
    return axios.get(API + "report/").then(res => res).catch(err =>err.response);
}