import React, { useRef, useState, KeyboardEvent, ChangeEvent, useEffect } from 'react';
import Swal from 'sweetalert2';
import { API_Add_Kind, API_Get_Category_Work, API_Get_Work } from '../../Utils/api';

interface ItemStruct {
    id_work?: string
    id_category: string
    name_kind: string
}

interface Struct {
    refresh: () => void
}

const Kind_Add = (props : Struct) => {

    const [Toggle, setToggle] = useState(false);

    const [Work, setWork] = useState([]);
    const [Category, setCategory] = useState([]);

    const Data = useRef<ItemStruct>({
        id_work: '',
        id_category: '',
        name_kind: '',
    });

    const [Error, setError] = useState({
        id_work: false,
        id_category: false,
        name_kind: false,
    });

    const onEnter = (event : KeyboardEvent<HTMLInputElement>) : void => {
        event.key === 'Enter' && onSubmitData()
    }

    const onEsc = () : void => {
        document.onkeydown = (event) => {
            event.keyCode === 27 && setToggle(false);
        }
    }

    const onSubmitData = () : void => {
        if (
            CheckEmpty(Data.current.id_work || '', 'id_work') &&
            CheckEmpty(Data.current.id_category, 'id_category') &&
            CheckEmpty(Data.current.name_kind, 'name_kind')
        ) {
            Swal.fire({
                icon: 'warning',
                title: 'คำเตือน',
                text: 'คุณต้องการที่จะเพิ่มหมวดงาน ใช่ หรือ ไม่?',
                timer: 5000,
                timerProgressBar: true,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'ใช่',
                cancelButtonText: 'ไม่',
            }).then(result => {
                if (result.value) {
                    PostData();
                }
            });
        }
    }

    const CheckEmpty = (value: string, id : string) : boolean => {
        if (value !== '') {
            setError(preState => ({
                ...preState,
                [id]: false
            }));
            return true;
        } else {
            document.getElementById(id)?.focus();
            setError(preState => ({
                ...preState,
                [id]: true
            }));
            return false;
        }
    }

    const PostData = () : void => {
        API_Add_Kind(Data.current).then(res => {
            Swal.fire({
                icon: res.status ? 'success' : 'error',
                title: res.status ? 'สำเสร็จ' : 'เกิดข้อผิดพลาด',
                text: res.message,
                timer: 5000,
                timerProgressBar: true,
            }).then(() => {
                if (res.status) {
                    props.refresh();
                    ClearInput();
                }
            });
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด',
                text: 'ไม่สามารถเชื่อมต่อฐานข้อมูลได้',
                timer: 5000,
                timerProgressBar: true,
            });
        });
    }

    const ClearInput = () => {
        Data.current.name_kind = "";

        const getid = document.getElementById("name_kind") as HTMLInputElement;
        getid.value = "";
    }

    const onChangeData = (event : ChangeEvent<any>) : void => {
        let data = {
            ...Data.current,
            [event.target.id]: event.target.value
        }
        Data.current = data;
        if (event.target.id === 'id_work') {
            Get_Category(event.target.value);
        }
    }

    const Get_Work = () => {
        API_Get_Work('').then(res => {
            setWork(res.data);
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด',
                text: 'ไม่สามารถเชื่อมต่อฐานข้อมูลได้',
                timer: 5000,
                timerProgressBar: true,
            });
        });
    }

    const Get_Category = (id : string) => {
        API_Get_Category_Work(id).then(res => {
            setCategory(res.data);
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด',
                text: 'ไม่สามารถเชื่อมต่อฐานข้อมูลได้',
                timer: 5000,
                timerProgressBar: true,
            });
        });
    }

    useEffect(() => {
        onEsc();
        Get_Work();
    }, []);

    return (
        <>
        <button onClick={() => setToggle(!Toggle)} className="button is-link">เพิ่มหมวดหมู่</button>
        <div className={`modal ${Toggle ? 'is-active' : ''}`}>
            <div onClick={() => setToggle(!Toggle)} className="modal-background" />
            <div className="modal-card">
                <header className="modal-card-head">
                <p className="modal-card-title">เพิ่มหมวดงาน</p>
                <button onClick={() => setToggle(!Toggle)} className="delete" aria-label="close" />
                </header>
                <section className="modal-card-body">
                    <div className="field">
                        <label className="label">หมวดงาน</label>
                        <div className="control">
                            <select defaultValue="" id="id_work" onChange={onChangeData} className={`input ${Error.id_work ? 'is-danger' : ''}`}>
                                <option disabled value="">กรุณาเลือกหมวดงาน</option>
                                {
                                    Work.length > 0 &&
                                    Work.map((item, index) => (
                                        <option key={index} value={item['id_work']}>{item['name_work']}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    <div className="field">
                        <label className="label">ประเภท</label>
                        <div className="control">
                            <select defaultValue="" id="id_category" onChange={onChangeData} className={`input ${Error.id_work ? 'is-danger' : ''}`}>
                                <option disabled value="">กรุณาเลือกประเภท</option>
                                {
                                    Category.length > 0 &&
                                    Category.map((item, index) => (
                                        <option key={index} value={item['id_category']}>{item['name_category']}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    <div className="field">
                        <label className="label">ชื่อหมวดหมู่</label>
                        <div className="control">
                            <input onKeyPress={onEnter} onChange={onChangeData} 
                            autoFocus className={`input ${Error.name_kind ? 'is-danger' : ''}`} type="text" id="name_kind" placeholder="หมวดหมู่" />
                        </div>
                    </div>

                    <button onClick={() => onSubmitData()} className="button is-link is-fullwidth">เพิ่มหมวดหมู่</button>
                </section>
            </div>
        </div>

        </>
    );
}

export default Kind_Add;