import React, { useRef } from 'react';
import { useHistory, useLocation } from 'react-router';
import { BsGraphUp, BsBriefcaseFill, BsPower, BsPersonFill,
     BsBuilding, BsBucketFill,  BsCardList, BsCloudFill, BsPeople, BsDownload } from 'react-icons/bs';
import Swal from 'sweetalert2';
import ReactTooltip from 'react-tooltip';
import { API_Logout } from '../Utils/api';

const Menu = () => {

    const history = useHistory();

    const path = useLocation();

    const ListMenu = useRef([
        {path: '/job', name: 'จัดการอาชีพ', icon: <BsBriefcaseFill />},
        {path: '/country', name: 'จัดการประเทศ', icon: <BsCloudFill />},
        {path: '/brand', name: 'จัดการแบรนด์', icon: <BsBuilding />},
        {path: '/work', name: 'จัดการหมวดงาน', icon: <BsCardList />},
        {path: '/category', name: 'จัดการประเภท', icon: <BsCardList />},
        {path: '/kind', name: 'จัดการหมวดหมู่', icon: <BsCardList />},
        {path: '/product', name: 'จัดการสินค้า', icon: <BsBucketFill />},
        {path: '/member', name: 'จัดการสมาชิก', icon: <BsPersonFill />},
        {path: '/customer', name: 'จัดการลูกค้า', icon: <BsPeople />},
        {path: '/update', name: 'จัดการอัพเดท', icon: <BsDownload />},
    ]);

    const Logout = () => {
        Swal.fire({
            icon: 'warning',
            title: 'คำเตือน',
            text: 'คุณต้องการที่จะออกจากระบบ ใช่ หรือ ไม่?',
            showConfirmButton: true,
            confirmButtonText: 'ใช่',
            showCancelButton: true,
            cancelButtonText: 'ไม่',
        }).then(res => {
            if (res.value) {
                API_Logout();
                localStorage.removeItem('token');
                Swal.fire({
                    icon: 'success',
                    title: 'สำเร็จ',
                    text: 'ออกจากระบบเสร็จสิ้น',
                    timer: 5000,
                    timerProgressBar: true
                }).then(() => {
                    window.location.href = '/login';
                });
            }
        });
    }

    return (
        <div className="menu-bar">
            <h4 className="has-text-centered title is-4 mt-3 has-text-link">Product Connect Admin</h4>
            <aside className="menu">
                <p className="menu-label">
                    ทั่วไป
                </p>
                <ul className="menu-list">
                    <li data-for="menu" data-tip="แดชบอร์ด" onClick={() => history.push('/')}>
                        <a className={`${path.pathname === '/' ? 'is-active' : ''}`}>
                            <BsGraphUp /> <b>แดชบอร์ด</b>
                        </a>
                    </li>
                </ul>
                <p className="menu-label">
                    บริหารจัดการ
                </p>
                <ul className="menu-list">
                    {
                        ListMenu.current.map((item, index) => (
                            <li data-for="menu" data-tip={item.name} key={index} onClick={() => history.push(item.path)}>
                                <a className={`${item.path === path.pathname ? 'is-active' : ''}`}>
                                    {item.icon} <b>{item.name}</b>
                                </a>
                            </li>
                        ))
                    }
                </ul>
                <p className="menu-label">
                    เกี่ยวกับเรา
                </p>
                <ul className="menu-list">
                    <li data-for="menu" data-tip="ออกจากระบบ" onClick={() => Logout()}><a><BsPower /> <b>ออกจากระบบ</b></a></li>
                </ul>
                <ReactTooltip id="menu" place="right" effect="solid" />
            </aside>
        </div>
    );
}

export default Menu;