import React, { useEffect, KeyboardEvent, ChangeEvent, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import Swal from 'sweetalert2';
import Menu from '../../Components/Menu';
import { API_Get_Member, API_Update_Member } from '../../Utils/api';

interface ItemStruct {
    id_member: string
    username: string
    firstname: string
    lastname: string
}

const Member_Edit = () => {

    const history = useHistory();

    const param = useParams<{
        id: string
    }>();

    const Data = useRef<ItemStruct>({
        id_member: param.id,
        username: '',
        firstname: '',
        lastname: '',
    });

    const [Error, setError] = useState({
        username: false as boolean,
        firstname: false as boolean,
        lastname: false as boolean,
    });

    const GetData = () : void => {
        API_Get_Member(param.id).then(res => {
            if (res.data.length > 0) {
                Data.current.username = res.data[0].username;
                Data.current.firstname = res.data[0].firstname;
                Data.current.lastname = res.data[0].lastname;
    
                (document.getElementById('username') as HTMLInputElement).value = res.data[0].username;
                (document.getElementById('firstname') as HTMLInputElement).value = res.data[0].firstname;
                (document.getElementById('lastname') as HTMLInputElement).value = res.data[0].lastname;
            } else {
                Swal.fire({
                    icon: "warning",
                    title: "คำเตือน",
                    text: "ไม่พบข้อมูล",
                    timer: 5000,
                    timerProgressBar: true
                }).then(() => {
                    history.push("/member")
                });
            }
        }).catch(() => {
            Swal.fire({
                icon: "error",
                title: "เกิดข้อผิดพลาด",
                text: "ไม่สามารถเชื่อมต่อเซิฟเวอร์ได้",
                timer: 5000,
                timerProgressBar: true
            });
        });
    }

    const onEnter = (event : KeyboardEvent<HTMLInputElement>) : void => {
        event.key === 'Enter' && onSubmitData()
    }

    const onSubmitData = () : void => {
        if (
            isNotEmpty(Data.current.username, 'username') &&
            isNotEmpty(Data.current.firstname, 'firstname') &&
            isNotEmpty(Data.current.lastname, 'lastname')
        ) {
            Swal.fire({
                icon: 'warning',
                title: 'คำเตือน',
                text: 'คุณต้องการที่จะแก้ไขข้อมูล ใช่ หรือ ไม่?',
                timer: 5000,
                timerProgressBar: true,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'ใช่',
                cancelButtonText: 'ไม่',
            }).then(result => {
                if (result.value) {
                    PostData();
                }
            });
        }
    }

    const PostData = () : void => {
        console.log(Data.current);
        API_Update_Member(Data.current).then(res => {
            if (res.status) {
                Swal.fire({
                    icon: 'success',
                    title: 'สำเร็จ',
                    text: res.message,
                    timer: 5000,
                    timerProgressBar: true,
                }).then(() => {
                    history.push('/member');
                })
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'เกิดข้อผิดพลาด',
                    text: res.message,
                    timer: 5000,
                    timerProgressBar: true,
                });
            }
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด',
                text: 'ไม่สามารถเชื่อมต่อเซิฟเวอร์ได้',
                timer: 5000,
                timerProgressBar: true,
            });
        });
    }

    const onChangeData = (event : ChangeEvent<HTMLInputElement>) : void => {
        Data.current = {
            ...Data.current,
            [event.target.id]: event.target.value
        };
    }

    const isNotEmpty = (value : string, id : string) : boolean => {
        if (value.length > 0) {
            setError(prevState => ({
                ...prevState,
                [id]: false
            }));
            return true;
        } else {
            document.getElementById(id)?.focus();
            setError(prevState => ({
                ...prevState,
                [id]: true
            }));
            return false;
        }
    }

    useEffect(() => {
        GetData();
    }, []);

    return (
        <div className="is-flex" style={{gap: '10px'}}>
            <Menu />
            <div className="p-2 container">
                <h5 className="subtitle is-5 mt-3 mb-3">จัดการกับสมาชิก / แก้ไขข้อมูลสมาชิก</h5>
                <div className="card">
                    <div className="card-content">
                        <div className="field">
                            <label className="label">ชื่อผู้ใช้งาน</label>
                            <div className="control">
                                <input onKeyPress={onEnter} id="username" onChange={onChangeData} 
                                autoFocus className={`input ${Error.username ? 'is-danger': ''}`} type="text" placeholder="ชื่อผู้ใช้งาน" />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">ชื่อจริง</label>
                            <div className="control">
                                <input onKeyPress={onEnter} id="firstname" onChange={onChangeData} 
                                className={`input ${Error.firstname ? 'is-danger': ''}`} type="text" placeholder="ชื่อจริงของท่าน" />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">นามสกุล</label>
                            <div className="control">
                                <input onKeyPress={onEnter} id="lastname" onChange={onChangeData} 
                                className={`input ${Error.lastname ? 'is-danger': ''}`} type="text" placeholder="นามสกุลของท่าน" />
                            </div>
                        </div>
                        <button onClick={() => onSubmitData()} className="button is-link is-fullwidth">แก้ไขข้อมูล</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Member_Edit;