import React, { KeyboardEvent, ChangeEvent, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import Swal from 'sweetalert2';
import Menu from '../../Components/Menu';
import { API_Image_Brand } from '../../Utils/api';

interface ItemStruct {
    id_brand: string
    image_file?: File
}

const Brand_Image = () => {

    const param = useParams<{
        id: string
    }>();

    const history = useHistory();

    const Data = useRef<ItemStruct>({
        id_brand: param.id,
        image_file: undefined
    });
    
    const [Error, setError] = useState<boolean>(false);


    const onEnter = (event : KeyboardEvent<HTMLInputElement>) : void => {
        event.key === 'Enter' && onSubmitData()
    }

    const onSubmitData = () : void => {
        if (CheckEmpty()) {
            Swal.fire({
                icon: 'warning',
                title: 'คำเตือน',
                text: 'คุณต้องการที่จะเปลื่ยนรูปภาพ ใช่ หรือ ไม่?',
                timer: 5000,
                timerProgressBar: true,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'ใช่',
                cancelButtonText: 'ไม่',
            }).then(result => {
                if (result.value) {
                    PostData();
                }
            });
        }
    }

    const PostData = () : void => {
        API_Image_Brand(Data.current.id_brand, Data.current.image_file as File).then(res => {
            Swal.fire({
                icon: res.status ? 'success' : 'error',
                title: res.status ? 'สำเสร็จ' : 'เกิดข้อผิดพลาด',
                text: res.message,
                timer: 5000,
                timerProgressBar: true,
            }).then(() => {
                if (res.status) {
                    history.push('/brand');
                }
            });
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด',
                text: 'ไม่สามารถเชื่อมต่อฐานข้อมูลได้',
                timer: 5000,
                timerProgressBar: true,
            });
        });
    }

    const CheckEmpty = () : boolean => {
        if (Data.current.image_file !== undefined) {
            setError(false);
            return true;
        } else {
            document.getElementById('image_file')?.focus();
            setError(true);
            return false;
        }
    }

    const onChangeData = (event : ChangeEvent<HTMLInputElement>) : void => {
        const inputfile : FileList = event.target.files as FileList
        Data.current.image_file = inputfile[0] as File;
        const url = URL.createObjectURL(inputfile[0])
        document.getElementById('image_preview')?.setAttribute('src', url);
    }

    return (
        <div className="is-flex" style={{gap: '10px'}}>
            <Menu />
            <div className="p-2 container">
                <h5 className="subtitle is-5 mt-3 mb-3">จัดการกับแบรนด์ / เปลื่ยนโลโก้แบรนด์</h5>
                <div className="card">
                    <div className="card-content">
                        <div className="field">
                            <label className="label">ไฟส์รูปภาพ</label>
                            <div className="control">
                                <input accept="image/*" onKeyPress={onEnter} onChange={onChangeData} id="image_file"
                                autoFocus className={`input ${Error ? 'is-danger' : ''}`} type="file" placeholder="รูปภาพ" />
                            </div>
                        </div>
                        <img src="" id="image_preview" alt="" />
                        <button onClick={() => onSubmitData()} className="button is-link is-fullwidth">เปลื่ยนรูปภาพ</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Brand_Image;