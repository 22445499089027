import React, { useEffect, KeyboardEvent, ChangeEvent, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import Swal from 'sweetalert2';
import Menu from '../../Components/Menu';
import {  API_Get_Work, API_Get_Country, API_Update_Work } from '../../Utils/api';

interface ItemStruct {
    id_country: string
    id_work: string
    name_work: string
}

const Work_Edit = () => {

    const history = useHistory();

    const param = useParams<{
        id: string
    }>();

    const Data = useRef<ItemStruct>({
        id_work: param.id,
        id_country: '',
        name_work: ''
    });
    
    const [Error, setError] = useState({
        id_country: false,
        name_work: false
    });

    const [Country, setCountry] = useState([]);

    const onEnter = (event : KeyboardEvent<HTMLInputElement>) : void => {
        event.key === 'Enter' && onSubmitData()
    }

    const onSubmitData = () : void => {
        if (
            CheckEmpty(Data.current.id_country, "id_country") &&
            CheckEmpty(Data.current.name_work, "name_work")
            ) {
            Swal.fire({
                icon: 'warning',
                title: 'คำเตือน',
                text: 'คุณต้องการที่จะแก้ไขข้อมูล ใช่ หรือ ไม่?',
                timer: 5000,
                timerProgressBar: true,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'ใช่',
                cancelButtonText: 'ไม่',
            }).then(result => {
                if (result.value) {
                    PostData();
                }
            });
        }
    }

    const PostData = () : void => {
        API_Update_Work(Data.current).then(res => {
            Swal.fire({
                icon: res.status ? 'success' : 'error',
                title: res.status ? 'สำเสร็จ' : 'เกิดข้อผิดพลาด',
                text: res.message,
                timer: 5000,
                timerProgressBar: true,
            }).then(() => {
                if (res.status) {
                    history.push('/work');
                }
            });
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด',
                text: 'ไม่สามารถเชื่อมต่อฐานข้อมูลได้',
                timer: 5000,
                timerProgressBar: true,
            });
        });
    }

    const CheckEmpty = (value: string, id : string) : boolean => {
        if (value !== '') {
            setError(preState => ({
                ...preState,
                [id]: false
            }));
            return true;
        } else {
            document.getElementById(id)?.focus();
            setError(preState => ({
                ...preState,
                [id]: true
            }));
            return false;
        }
    }

    const onChangeData = (event : ChangeEvent<HTMLInputElement | HTMLSelectElement>) : void => {
        Data.current.name_work = event.target.value;
    }

    const Get_Data = () => {
        API_Get_Work(param.id).then(res => {
            Data.current = res.data[0];
            const def = document.getElementById("name_work") as HTMLInputElement;
            const cou = document.getElementById("id_country") as HTMLSelectElement;
            def.value = Data.current.name_work;
            cou.value = Data.current.id_country;
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด',
                text: 'ไม่สามารถเชื่อมต่อฐานข้อมูลได้',
                timer: 5000,
                timerProgressBar: true,
            }).then(() => {
                history.push('/work');
            })
        });
    }

    const Get_Country = () => {
        API_Get_Country('').then(res => {
            setCountry(res.data);
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด',
                text: 'ไม่สามารถเชื่อมต่อฐานข้อมูลได้',
                timer: 5000,
                timerProgressBar: true,
            });
        });
    }

    useEffect(() => {
        Get_Data();
        Get_Country();
    }, []);

    return (
        <div className="is-flex" style={{gap: '10px'}}>
            <Menu />
            <div className="p-2 container">
                <h5 className="subtitle is-5 mt-3 mb-3">จัดการกับหมวดงาน / แก้ไขข้อมูลหมวดงาน</h5>
                <div className="card">
                    <div className="card-content">
                        <div className="field">
                            <label className="label">ประเทศที่ใช้</label>
                            <div className="control">
                                <select defaultValue="" id="id_country" onChange={onChangeData} className={`input ${Error.id_country ? 'is-danger' : ''}`}>
                                    <option disabled value="">กรุณาเลือกประเทศ</option>
                                    {
                                        Country.length > 0 &&
                                        Country.map((item, index) => (
                                            <option key={index} value={item['id_country']}>{item['name_country']}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">ชื่อหมวดงาน</label>
                            <div className="control">
                                <input onKeyPress={onEnter} onChange={onChangeData} id="name_work"
                                autoFocus className={`input ${Error.name_work ? 'is-danger' : ''}`} type="text" placeholder="ชื่อหมวดงาน" />
                            </div>
                        </div>
                        <button onClick={() => onSubmitData()} className="button is-link is-fullwidth">แก้ไขข้อมูล</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Work_Edit;