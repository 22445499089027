import React, { useEffect, KeyboardEvent, ChangeEvent, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import Swal from 'sweetalert2';
import Menu from '../../Components/Menu';
import { API_Get_Category, API_Get_Work, API_Update_Category } from '../../Utils/api';

interface ItemStruct {
    id_category: string
    id_work: string
    name_category: string
}

const Category_Edit = () => {

    const history = useHistory();

    const param = useParams<{
        id: string
    }>();

    const [Data, setData] = useState<ItemStruct>({
        id_category: param.id,
        id_work: '',
        name_category: ''
    });

    
    const [Error, setError] = useState({
        id_work: false,
        name_work: false,
    });

    const [Work, setWork] = useState([]);


    const onEnter = (event : KeyboardEvent<HTMLInputElement>) : void => {
        event.key === 'Enter' && onSubmitData()
    }

    const onSubmitData = () : void => {
        if (
            CheckEmpty('id_work', Data.id_work) &&
            CheckEmpty('name_category', Data.name_category)
        ) {
            Swal.fire({
                icon: 'warning',
                title: 'คำเตือน',
                text: 'คุณต้องการที่จะแก้ไขข้อมูล ใช่ หรือ ไม่?',
                timer: 5000,
                timerProgressBar: true,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'ใช่',
                cancelButtonText: 'ไม่',
            }).then(result => {
                if (result.value) {
                    PostData();
                }
            });
        }
    }

    const PostData = () : void => {
        API_Update_Category(Data).then(res => {
            Swal.fire({
                icon: res.status ? 'success' : 'error',
                title: res.status ? 'สำเสร็จ' : 'เกิดข้อผิดพลาด',
                text: res.message,
                timer: 5000,
                timerProgressBar: true,
            }).then(() => {
                if (res.status) {
                    history.push('/category');
                }
            });
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด',
                text: 'ไม่สามารถเชื่อมต่อฐานข้อมูลได้',
                timer: 5000,
                timerProgressBar: true,
            });
        });
    }

    const CheckEmpty = (id : string, value : string) : boolean => {
        if (value !== '') {
            setError(preState => ({
                ...preState,
                [id]: false
            }));
            return true;
        } else {
            document.getElementById(id)?.focus();
            setError(preState => ({
                ...preState,
                [id]: true
            }));
            return false;
        }
    }

    const onChangeData = (event : ChangeEvent<any>) : void => {
        setData(preState => ({
            ...preState,
            [event.target.id]: event.target.value
        }));
    }

    const Get_Data = () => {
        API_Get_Category(param.id).then(res => {
            setData(res.data[0]);
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด',
                text: 'ไม่สามารถเชื่อมต่อฐานข้อมูลได้',
                timer: 5000,
                timerProgressBar: true,
            }).then(() => {
                history.push('/category');
            })
        });
    }

    const Get_Work = () => {
        API_Get_Work('').then(res => {
            setWork(res.data);
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด',
                text: 'ไม่สามารถเชื่อมต่อฐานข้อมูลได้',
                timer: 5000,
                timerProgressBar: true,
            }).then(() => {
                history.push('/category');
            })
        });
    }

    useEffect(() => {
        Get_Work();
        Get_Data();
    }, []);

    return (
        <div className="is-flex" style={{gap: '10px'}}>
            <Menu />
            <div className="p-2 container">
                <h5 className="subtitle is-5 mt-3 mb-3">จัดการกับประเภท / แก้ไขข้อมูลประเภท</h5>
                <div className="card">
                    <div className="card-content">
                        <div className="field">
                            <label className="label">หมวดงาน</label>
                            <div className="control">
                                <select value={Data.id_work} defaultValue="" id="id_work" onChange={onChangeData} className={`input ${Error.id_work ? 'is-danger' : ''}`}>
                                    <option disabled value="">กรุณาเลือกหมวดงาน</option>
                                    {
                                        Work.map((item, index) => (
                                            <option key={index} value={item['id_work']}>{item['name_work']}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">ชื่อประเภท</label>
                            <div className="control">
                                <input value={Data.name_category} onKeyPress={onEnter} onChange={onChangeData} id="name_category"
                                autoFocus className={`input ${Error.name_work ? 'is-danger' : ''}`} type="text" placeholder="ชื่อประเภท" />
                            </div>
                        </div>
                        <button onClick={() => onSubmitData()} className="button is-link is-fullwidth">แก้ไขข้อมูล</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Category_Edit;