import React, { useEffect, KeyboardEvent, ChangeEvent, useRef, useState } from 'react';
import { useParams } from 'react-router';
import Swal from 'sweetalert2';
import { API_Login } from '../Utils/api';

interface ItemStruct {
    username: string
    password: string
}

const Login = () => {

    const Data = useRef<ItemStruct>({
        username: '',
        password: '',
    });

    const [Error, setError] = useState({
        username: false as boolean,
        password: false as boolean,
    });


    const onEnter = (event : KeyboardEvent<HTMLInputElement>) : void => {
        event.key === 'Enter' && onSubmitData()
    }

    const onSubmitData = () : void => {
        if (
            CheckEmpty(Data.current.username, 'username') &&
            Check_Password()
        ) {
            PostData();
        }
    }

    const PostData = () : void => {
        API_Login(Data.current).then(res => {
            Swal.fire({
                icon: res.status ? 'success' : 'error',
                title: res.status ? 'สำเสร็จ' : 'เกิดข้อผิดพลาด',
                text: res.message,
                timer: 5000,
                timerProgressBar: true,
            }).then(() => {
                if (res.status) {
                    window.location.href = '/';
                    localStorage.setItem('token', res.token['access_token'])
                    localStorage.setItem('refresh_token', res.token['refresh_token'])
                }
            });
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด',
                text: 'ไม่สามารถเชื่อมต่อฐานข้อมูลได้',
                timer: 5000,
                timerProgressBar: true,
            });
        });
    }

    const onChangeData = (event : ChangeEvent<HTMLInputElement>) : void => {
        const data = {
            ...Data.current,
            [event.target.id]: event.target.value
        };
        Data.current = data;
    }

    const CheckEmpty = (value : string, id : string) : boolean => {
        if (value !== '') {
            setError(preState => ({
                ...preState,
                [id]: false
            }));
            return true;
        } else {
            document.getElementById(id)?.focus();
            setError(preState => ({
                ...preState,
                [id]: true
            }));
            return false;
        }
    }

    const Check_Password = () : boolean => {
        let status = false;

        if (Data.current.password !== '') {
            if (Data.current.password.length >= 10) {
                status = true;
            }
        }

        if (status) {
            setError(preState => ({
                ...preState,
                password: false
            }));
            return true;
        } else {
            setError(preState => ({
                ...preState,
                password: true
            }));
            document.getElementById('password')?.focus();
            return false;
        }
    }


    return (
        <div className="is-flex is-justify-content-center is-align-items-center" style={{height: '100vh'}}>
            <div className="card" style={{width: '300px'}}>
                <div className="card-content">
                    <h3 className="title is-3 has-text-centered">เข้าสู่ระบบหลังบ้าน</h3>
                    <div className="field">
                        <label className="label">ชื่อผู้ใช้งาน</label>
                        <div className="control">
                            <input onKeyPress={onEnter} onChange={onChangeData} id="username"
                            autoFocus className={`input ${Error.username ? 'is-danger' : ''}`} type="text" placeholder="อีเมล" />
                        </div>
                    </div>
                    <div className="field">
                        <label className="label">รหัสผ่าน</label>
                        <div className="control">
                            <input onKeyPress={onEnter} onChange={onChangeData} id="password"
                            className={`input ${Error.password ? 'is-danger' : ''}`} type="password" placeholder="รหัสผ่าน" />
                        </div>
                    </div>
                    <button className="button is-link is-fullwidth" onClick={() => onSubmitData()}>เข้าสู่ระบบ</button>
                </div>
            </div>
        </div>
    );
}

export default Login;