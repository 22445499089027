import React, { useRef, useState, KeyboardEvent, ChangeEvent, useEffect } from 'react';
import Swal from 'sweetalert2';
import { API_Add_Brand, API_Get_Country } from '../../Utils/api';

interface ItemStruct {
    id_country: string
    name_brand: string
    website: string
    tel: string
    email: string
    address: string
    image?: File
}

interface Item {
    refresh: () => void
}

const Brand_Add = (props : Item) => {

    const [Toggle, setToggle] = useState(false);

    const [Country, setCountry] = useState([]);

    const Data = useRef<ItemStruct>({
        id_country: '',
        name_brand: '',
        website: '',
        tel: '',
        email: '',
        address: '',
        image: undefined
    });

    const [Error, setError] = useState({
        id_country: false,
        name_brand: false,
        website: false,
        tel: false,
        email: false,
        address: false,
        image: false
    });

    const onEnter = (event : KeyboardEvent<HTMLInputElement>) : void => {
        event.key === 'Enter' && onSubmitData()
    }

    const onSubmitData = () : void => {
        if (
            CheckEmpty(Data.current.id_country, 'id_country') &&
            CheckEmpty(Data.current.name_brand, 'name_brand') &&
            CheckEmpty(Data.current.website, 'website') &&
            CheckEmpty(Data.current.tel, 'tel') &&
            CheckEmpty(Data.current.email, 'email') &&
            CheckEmpty(Data.current.address, 'address') &&
            CheckFile('image', Data.current.image)
        ) {
            Swal.fire({
                icon: 'warning',
                title: 'คำเตือน',
                text: 'คุณต้องการที่จะเพิ่มแบรนด์ ใช่ หรือ ไม่?',
                timer: 5000,
                timerProgressBar: true,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'ใช่',
                cancelButtonText: 'ไม่',
            }).then(result => {
                if (result.value) {
                    PostData();
                }
            });
        }
    }

    const CheckEmpty = (value: string, id : string) : boolean => {
        if (value !== '') {
            setError(preState => ({
                ...preState,
                [id]: false
            }));
            return true;
        } else {
            document.getElementById(id)?.focus();
            setError(preState => ({
                ...preState,
                [id]: true
            }));
            return false;
        }
    }

    const CheckFile = (id: string, value?: File) : boolean => {
        if (value !== undefined) {
            setError(preState => ({
                ...preState,
                [id]: false
            }));
            return true;
        } else {
            document.getElementById(id)?.focus();
            setError(preState => ({
                ...preState,
                [id]: true
            }));
            return false;
        }
    }

    const PostData = () : void => {
        API_Add_Brand(Data.current).then(res => {
            Swal.fire({
                icon: res.status ? 'success' : 'error',
                title: res.status ? 'สำเสร็จ' : 'เกิดข้อผิดพลาด',
                text: res.message,
                timer: 5000,
                timerProgressBar: true,
            }).then(() => {
                if (res.status) {
                    props.refresh();
                }
            });
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด',
                text: 'ไม่สามารถเชื่อมต่อฐานข้อมูลได้',
                timer: 5000,
                timerProgressBar: true,
            });
        });
    }

    const onChangeData = (event : ChangeEvent<any>) : void => {
        let data = Data.current;
        if (event.target.id === 'image') {
            const file = event.target.files as FileList;
            data = {
                ...Data.current,
                [event.target.id]: file[0]
            }
            const url = URL.createObjectURL(file[0]);
            document.getElementById('image_preview')?.setAttribute('src', url);
        } else {
            data = {
                ...Data.current,
                [event.target.id]: event.target.value
            }
        }
        Data.current = data;
    }

    const Get_Country = () => {
        API_Get_Country('').then(res => {
            setCountry(res.data);
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด',
                text: 'ไม่สามารถเชื่อมต่อฐานข้อมูลได้',
                timer: 5000,
                timerProgressBar: true,
            });
        });
    }

    const onEsc = () : void => {
        document.onkeydown = (event) => {
            event.keyCode === 27 && setToggle(false);
        }
    }

    useEffect(() => {
        onEsc();
        Get_Country();
    }, []);


    return (
        <>
        <button onClick={() => setToggle(!Toggle)} className="button is-link">เพิ่มแบรนด์</button>
        <div className={`modal ${Toggle ? 'is-active' : ''}`}>
            <div onClick={() => setToggle(!Toggle)} className="modal-background" />
            <div className="modal-card">
                <header className="modal-card-head">
                <p className="modal-card-title">เพิ่มแบรนด์</p>
                <button onClick={() => setToggle(!Toggle)} className="delete" aria-label="close" />
                </header>
                <section className="modal-card-body">
                    <div className="field">
                        <label className="label">ประเทศที่วางจำหน่าย</label>
                        <div className="control">
                            <select defaultValue="" id="id_country" onChange={onChangeData} className={`input ${Error.id_country ? 'is-danger' : ''}`}>
                                <option disabled value="">กรุณาเลือกประเทศ</option>
                                {
                                    Country.length > 0 &&
                                    Country.map((item, index) => (
                                        <option key={index} value={item['id_country']}>{item['name_country']}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    <div className="field">
                        <label className="label">ชื่อแบรนด์</label>
                        <div className="control">
                            <input onKeyPress={onEnter} onChange={onChangeData} 
                            autoFocus className={`input ${Error.name_brand ? 'is-danger' : ''}`} type="text" id="name_brand" placeholder="ชื่อแบรนด์" />
                        </div>
                    </div>
                    <div className="field">
                        <label className="label">เว็บไซต์</label>
                        <div className="control">
                            <input onKeyPress={onEnter} onChange={onChangeData} 
                            className={`input ${Error.website ? 'is-danger' : ''}`} type="text" id="website" placeholder="เว็บไซต์" />
                        </div>
                    </div>
                    <div className="field">
                        <label className="label">เบอร์โทร</label>
                        <div className="control">
                            <input onKeyPress={onEnter} onChange={onChangeData} 
                            className={`input ${Error.tel ? 'is-danger' : ''}`} type="text" id="tel" placeholder="เบอร์โทร" />
                        </div>
                    </div>
                    <div className="field">
                        <label className="label">อีเมล์</label>
                        <div className="control">
                            <input onKeyPress={onEnter} onChange={onChangeData} 
                            className={`input ${Error.email ? 'is-danger' : ''}`} type="email" id="email" placeholder="อีเมล์" />
                        </div>
                    </div>
                    <div className="field">
                        <label className="label">ที่ตั้ง</label>
                        <div className="control">
                            <textarea onChange={onChangeData} className={`input ${Error.address ? 'is-danger' : ''}`} placeholder="ที่ตั้งของบริษัท" id="address"></textarea>
                        </div>
                    </div>
                    <div className="field">
                        <label className="label">รูปภาพ</label>
                        <div className="control">
                            <input onKeyPress={onEnter} onChange={onChangeData} 
                            className={`input ${Error.image ? 'is-danger' : ''}`} type="file" id="image" placeholder="รูปภาพ" />
                        </div>
                    </div>
                    <img src="" alt="" id="image_preview" />

                    <button onClick={() => onSubmitData()} className="button is-link is-fullwidth">เพิ่มแบรนด์</button>
                </section>
            </div>
        </div>

        </>
    );
}

export default Brand_Add;