import React, { useRef, useState, KeyboardEvent, ChangeEvent, useEffect } from 'react';
import Swal from 'sweetalert2';
import { API_Add_Customer, API_Get_Brand } from '../../Utils/api';

interface ItemStruct {
    username: string
    password: string
    id_brand: string
}

interface Item {
    refresh: () => void
}

const Customer_Add = (props : Item) => {

    const [Toggle, setToggle] = useState(false);

    const Data = useRef<ItemStruct>({
        username: '',
        password: '',
        id_brand: '',
    });

    const [Error, setError] = useState({
        username: false,
        password: false,
        id_brand: false
    });

    const [Brand, setBrand] = useState([]);

    const onEnter = (event : KeyboardEvent<HTMLInputElement>) : void => {
        event.key === 'Enter' && onSubmitData()
    }

    const onSubmitData = () : void => {
        if (
            CheckEmpty(Data.current.username, 'username') &&
            CheckEmpty(Data.current.password, 'password') &&
            CheckEmpty(Data.current.id_brand, 'id_brand')
        ) {
            Swal.fire({
                icon: 'warning',
                title: 'คำเตือน',
                text: 'คุณต้องการที่จะเพิ่มผู้จัดการแบรนด์ ใช่ หรือ ไม่?',
                timer: 5000,
                timerProgressBar: true,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'ใช่',
                cancelButtonText: 'ไม่',
            }).then(result => {
                if (result.value) {
                    PostData();
                }
            });
        }
    }

    const CheckEmpty = (value: string, id : string) : boolean => {
        if (value !== '') {
            setError(preState => ({
                ...preState,
                [id]: false
            }));
            return true;
        } else {
            document.getElementById(id)?.focus();
            setError(preState => ({
                ...preState,
                [id]: true
            }));
            return false;
        }
    }

    const PostData = () : void => {
        API_Add_Customer(Data.current).then(res => {
            Swal.fire({
                icon: res.status ? 'success' : 'error',
                title: res.status ? 'สำเสร็จ' : 'เกิดข้อผิดพลาด',
                text: res.message,
                timer: 5000,
                timerProgressBar: true,
            }).then(() => {
                if (res.status) {
                    props.refresh();
                }
            });
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด',
                text: 'ไม่สามารถเชื่อมต่อฐานข้อมูลได้',
                timer: 5000,
                timerProgressBar: true,
            });
        });
    }

    const onChangeData = (event : ChangeEvent<any>) : void => {
        const data = {
                ...Data.current,
                [event.target.id]: event.target.value
            }
        Data.current = data;
    }

    const Get_Brand = () => {
        API_Get_Brand('').then(res => {
            setBrand(res.data);
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด',
                text: 'ไม่สามารถเชื่อมต่อฐานข้อมูลได้',
                timer: 5000,
                timerProgressBar: true,
            });
        });
    }

    const onEsc = () : void => {
        document.onkeydown = (event) => {
            event.keyCode === 27 && setToggle(false);
        }
    }

    useEffect(() => {
        onEsc();
        Get_Brand();
    }, []);



    return (
        <>
        <button onClick={() => setToggle(!Toggle)} className="button is-link">เพิ่มผู้จัดการแบรนด์</button>
        <div className={`modal ${Toggle ? 'is-active' : ''}`}>
            <div onClick={() => setToggle(!Toggle)} className="modal-background" />
            <div className="modal-card">
                <header className="modal-card-head">
                <p className="modal-card-title">เพิ่มผู้จัดการแบรนด์</p>
                <button onClick={() => setToggle(!Toggle)} className="delete" aria-label="close" />
                </header>
                <section className="modal-card-body">
                    <div className="field">
                        <label className="label">ชื่อผู้ใช้งาน</label>
                        <div className="control">
                            <input onKeyPress={onEnter} onChange={onChangeData} 
                            autoFocus className={`input ${Error.username ? 'is-danger' : ''}`} type="text" id="username" placeholder="ชื่อผู้ใช้งาน" />
                        </div>
                    </div>
                    <div className="field">
                        <label className="label">รหัสผ่าน</label>
                        <div className="control">
                            <input onKeyPress={onEnter} onChange={onChangeData} 
                            className={`input ${Error.password ? 'is-danger' : ''}`} type="password" id="password" placeholder="รหัสผ่านของท่าน 10 ตัวขึ้นไป" />
                        </div>
                    </div>
                    <div className="field">
                        <label className="label">แบรนด์ที่ดูแล</label>
                        <div className="control">
                            <select defaultValue="" id="id_brand" onChange={onChangeData} className={`input ${Error.id_brand ? 'is-danger' : ''}`}>
                                <option disabled value="">กรุณาเลือกแบรนด์ที่ดูแล</option>
                                {
                                    Brand.length > 0 &&
                                    Brand.map((item, index) => (
                                        <option key={index} value={item['id_brand']}>{item['name_brand']}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    <button onClick={() => onSubmitData()} className="button is-link is-fullwidth">เพิ่มผู้จัดการแบรนด์</button>
                </section>
            </div>
        </div>

        </>
    );
}

export default Customer_Add;