import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { BsClockFill, BsLockFill, BsPencil, BsTrashFill } from "react-icons/bs";
import { useHistory } from "react-router";
import ReactTooltip from "react-tooltip";
import Swal from "sweetalert2";
import Menu from "../../Components/Menu";
import Member_Add from "../../Components/Member/add";
import { API_Get_Member, API_Remove_Member } from "../../Utils/api";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

interface ItemStruct {
  id_member: string;
  username: string;
  firstname: string;
  lastname: string;
  created_at: string;
  updated_at: string;
}

const Member_Page = () => {
  const history = useHistory();

  const [Data, setData] = useState<Array<ItemStruct>>([]);
  const [Page, setPage] = useState<number>(1);
  const [Total, setTotal] = useState<number>(0);

  const [Search, setSearch] = useState("");

  const Remove = (id: string): void => {
    Swal.fire({
      icon: "warning",
      title: "คำเตือน",
      text: "คุณต้องการที่จะลบ ใช่ หรือ ไม่?",
      timer: 5000,
      timerProgressBar: true,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "ใช่",
      cancelButtonText: "ไม่",
    }).then((result) => {
      if (result.value) {
        Remove_Data(id);
      }
    });
  };

  const onPage = (id: number) => {
    if (id > 0 && id <= Math.ceil(Total / 50)) {
      setPage(id);
    }
  };

  const Remove_Data = (id: string) => {
    API_Remove_Member(id)
      .then((res) => {
        Swal.fire({
          icon: res.status ? "success" : "error",
          title: res.status ? "สำเสร็จ" : "เกิดข้อผิดพลาด",
          text: res.message,
          timer: 5000,
          timerProgressBar: true,
        });
        if (res.status) {
          Get_Data();
        }
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ไม่สามารถเชื่อมต่อฐานข้อมูลได้",
          timer: 5000,
          timerProgressBar: true,
        });
      });
  };

  const onChangeData = (event : ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  }

  const Get_Data = (): void => {
    API_Get_Member("", Page, Search)
      .then((res) => {
        setTotal(res.total);
        setData(res.data);
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ไม่สามารถเชื่อมต่อฐานข้อมูลได้",
          timer: 5000,
          timerProgressBar: true,
        });
      });
  };

  useEffect(() => {
    Get_Data();
  }, [Page]);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      Get_Data();
    }, 800);
    return () => clearTimeout(timeOutId);
}, [Search]);

  return (
    <div className="is-flex" style={{ gap: "10px" }}>
      <Menu />
      <div className="p-2 container">
        <h5 className="subtitle is-5 mt-3 mb-3">จัดการกับสมาชิก</h5>
        <div className="card">
          <div className="card-content is-flex" style={{justifyContent: 'space-between'}}>
            <Member_Add refresh={Get_Data} />
            <div style={{width: '50%'}}>
              <label htmlFor="search_input">ค้นหาจากอีเมล</label>
              <input id="search_input" onChange={onChangeData} className="input" />
            </div>
          </div>
        </div>

        <div className="card mt-2">
          <div className="card-content">
            <table className="table is-hoverable is-striped is-fullwidth">
              <thead>
                <tr>
                  <th>ลำดับ</th>
                  <th>ชื่อผู้ใช้งาน</th>
                  <th>ชื่อจริง</th>
                  <th>นามสกุล</th>
                  <th>เครื่องมือ</th>
                </tr>
              </thead>
              <tbody>
                {Data.map((item, index) => (
                  <tr key={index}>
                    <td>{item.id_member}</td>
                    <td>{item.username}</td>
                    <td>{item.firstname}</td>
                    <td>{item.lastname}</td>
                    <td>
                      <button
                        data-tip={`สร้างเมื่อ [${item.created_at}] แก้ไขเมื่อ [${item.updated_at}]`}
                        data-for="data-member"
                        className="button is-small is-link mr-2"
                      >
                        <BsClockFill />
                      </button>
                      <button
                        onClick={() =>
                          history.push(`/member/password/${item.id_member}`)
                        }
                        data-tip="เปลื่ยนรหัสผ่าน"
                        data-for="data-member"
                        className="button is-small is-primary mr-2"
                      >
                        <BsLockFill />
                      </button>
                      <button
                        onClick={() =>
                          history.push(`/member/edit/${item.id_member}`)
                        }
                        data-tip="แก้ไขข้อมูล"
                        data-for="data-member"
                        className="button is-small is-warning mr-2"
                      >
                        <BsPencil />
                      </button>
                      <button
                        onClick={() => Remove(item.id_member)}
                        data-tip="ลบข้อมูล"
                        data-for="data-member"
                        className="button is-small is-danger"
                      >
                        <BsTrashFill />
                      </button>
                      <ReactTooltip
                        id="data-member"
                        place="bottom"
                        effect="solid"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div
            className="d-flex justify-content-center mt-5 mb-2"
            style={{ marginLeft: "60px" }}
          >
            <nav
              className="pagination is-centered is-rounded"
              style={{ margin: 0 }}
              role="navigation"
              aria-label="pagination"
            >
              <a
                onClick={() => onPage(Page - 1)}
                className="pagination-previous"
              >
                <FaAngleLeft />
              </a>
              <a onClick={() => onPage(Page + 1)} className="pagination-next">
                <FaAngleRight />
              </a>
              <ul className="pagination-list">
                {Array.from(Array(Math.ceil(Total / 50)), (v, i) => (
                  <li key={i}>
                    <a
                      onClick={() => onPage(i + 1)}
                      className={`pagination-link ${
                        i + 1 === Page ? "is-current" : ""
                      }`}
                      aria-label={`Goto page ${i + 1}`}
                    >
                      {i + 1}
                    </a>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Member_Page;
