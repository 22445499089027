import React, { useRef, useState, KeyboardEvent, ChangeEvent, useEffect } from 'react';
import Swal from 'sweetalert2';
import { API_Get_Country, API_Add_Work } from '../../Utils/api';

interface ItemStruct {
    id_country: string
    name_work: string
}

interface Struct {
    refresh: () => void
}

const Work_Add = (props : Struct) => {

    const [Toggle, setToggle] = useState(false);

    const Data = useRef<ItemStruct>({
        name_work: '',
        id_country: '',
    });

    const [Error, setError] = useState({
        id_country: false,
        name_work: false,
    });

    const [Country, setCountry] = useState([]);

    const onEnter = (event : KeyboardEvent<HTMLInputElement>) : void => {
        event.key === 'Enter' && onSubmitData()
    }

    const onEsc = () : void => {
        document.onkeydown = (event) => {
            event.keyCode === 27 && setToggle(false);
        }
    }

    const onSubmitData = () : void => {
        if (
            CheckEmpty(Data.current.name_work, 'name_work')
        ) {
            Swal.fire({
                icon: 'warning',
                title: 'คำเตือน',
                text: 'คุณต้องการที่จะเพิ่มหมวดงาน ใช่ หรือ ไม่?',
                timer: 5000,
                timerProgressBar: true,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'ใช่',
                cancelButtonText: 'ไม่',
            }).then(result => {
                if (result.value) {
                    PostData();
                }
            });
        }
    }

    const CheckEmpty = (value: string, id : string) : boolean => {
        if (value !== '') {
            setError(preState => ({
                ...preState,
                [id]: false
            }));
            return true;
        } else {
            document.getElementById(id)?.focus();
            setError(preState => ({
                ...preState,
                [id]: true
            }));
            return false;
        }
    }

    const PostData = () : void => {
        API_Add_Work(Data.current).then(res => {
            Swal.fire({
                icon: res.status ? 'success' : 'error',
                title: res.status ? 'สำเสร็จ' : 'เกิดข้อผิดพลาด',
                text: res.message,
                timer: 5000,
                timerProgressBar: true,
            }).then(() => {
                if (res.status) {
                    props.refresh();
                    ClearInput();
                }
            });
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด',
                text: 'ไม่สามารถเชื่อมต่อฐานข้อมูลได้',
                timer: 5000,
                timerProgressBar: true,
            });
        });
    }

    const ClearInput = () => {
        Data.current.name_work = "";
    
        const getid = document.getElementById("name_work") as HTMLInputElement;
        getid.value = "";

    }

    const onChangeData = (event : ChangeEvent<HTMLInputElement | HTMLSelectElement>) : void => {
        let data = {
            ...Data.current,
            [event.target.id]: event.target.value
        }
        Data.current = data;
    }

    const Get_Country = () => {
        API_Get_Country('').then(res => {
            setCountry(res.data);
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด',
                text: 'ไม่สามารถเชื่อมต่อฐานข้อมูลได้',
                timer: 5000,
                timerProgressBar: true,
            });
        });
    }

    useEffect(() => {
        onEsc();
        Get_Country();
    }, []);

    return (
        <>
        <button onClick={() => setToggle(!Toggle)} className="button is-link">เพิ่มหมวดงาน</button>
        <div className={`modal ${Toggle ? 'is-active' : ''}`}>
            <div onClick={() => setToggle(!Toggle)} className="modal-background" />
            <div className="modal-card">
                <header className="modal-card-head">
                <p className="modal-card-title">เพิ่มหมวดงาน</p>
                <button onClick={() => setToggle(!Toggle)} className="delete" aria-label="close" />
                </header>
                <section className="modal-card-body">
                    <div className="field">
                        <label className="label">ประเทศที่ใช้</label>
                        <div className="control">
                            <select defaultValue="" id="id_country" onChange={onChangeData} className={`input ${Error.id_country ? 'is-danger' : ''}`}>
                                <option disabled value="">กรุณาเลือกประเทศ</option>
                                {
                                    Country.length > 0 &&
                                    Country.map((item, index) => (
                                        <option key={index} value={item['id_country']}>{item['name_country']}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    <div className="field">
                        <label className="label">ชื่อหมวดงาน</label>
                        <div className="control">
                            <input onKeyPress={onEnter} onChange={onChangeData} 
                            autoFocus className={`input ${Error.name_work ? 'is-danger' : ''}`} type="text" id="name_work" placeholder="หมวดงาน" />
                        </div>
                    </div>

                    <button onClick={() => onSubmitData()} className="button is-link is-fullwidth">เพิ่มหมวดงาน</button>
                </section>
            </div>
        </div>

        </>
    );
}

export default Work_Add;