import React, { useRef, useState, KeyboardEvent, ChangeEvent, useEffect } from 'react';
import Swal from 'sweetalert2';
import { API_Add_Member, API_Get_Job } from '../../Utils/api';

interface ItemStruct {
    username: string
    password: string
    firstname: string
    lastname: string
    gender: string
    id_job: string
}

interface Item {
    refresh: () => void
}

const Member_Add = (props : Item) => {

    const [Toggle, setToggle] = useState(false);

    const Data = useRef<ItemStruct>({
        username: '',
        password: '',
        firstname: '',
        lastname: '',
        gender: '',
        id_job: '',
    });

    const [Error, setError] = useState({
        username: false,
        password: false,
        firstname: false,
        lastname: false,
        gender: false,
        id_job: false,
    });

    const [Job, setJob] = useState([]);

    const onEnter = (event : KeyboardEvent<HTMLInputElement>) : void => {
        event.key === 'Enter' && onSubmitData()
    }

    const onSubmitData = () : void => {
        if (
            CheckEmpty(Data.current.username, 'username') &&
            Check_Password() &&
            CheckEmpty(Data.current.firstname, 'firstname') &&
            CheckEmpty(Data.current.lastname, 'lastname') &&
            CheckEmpty(Data.current.gender, 'gender') &&
            CheckEmpty(Data.current.id_job, 'id_job')
        ) {
            Swal.fire({
                icon: 'warning',
                title: 'คำเตือน',
                text: 'คุณต้องการที่จะเพิ่มสมาชิก ใช่ หรือ ไม่?',
                timer: 5000,
                timerProgressBar: true,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'ใช่',
                cancelButtonText: 'ไม่',
            }).then(result => {
                if (result.value) {
                    PostData();
                }
            });
        }
    }

    const CheckEmpty = (value: string, id : string) : boolean => {
        if (value !== '') {
            setError(preState => ({
                ...preState,
                [id]: false
            }));
            return true;
        } else {
            document.getElementById(id)?.focus();
            setError(preState => ({
                ...preState,
                [id]: true
            }));
            return false;
        }
    }

    const PostData = () : void => {
        API_Add_Member(Data.current).then(res => {
            Swal.fire({
                icon: res.status ? 'success' : 'error',
                title: res.status ? 'สำเสร็จ' : 'เกิดข้อผิดพลาด',
                text: res.message,
                timer: 5000,
                timerProgressBar: true,
            }).then(() => {
                if (res.status) {
                    props.refresh();
                    ClearInput();
                }
            });
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด',
                text: 'ไม่สามารถเชื่อมต่อฐานข้อมูลได้',
                timer: 5000,
                timerProgressBar: true,
            });
        });
    }

    const ClearInput = () => {
        Data.current = {
            username: '',
            password: '',
            firstname: '',
            lastname: '',
            gender: '',
            id_job: '',
        }

        document.querySelectorAll('input').forEach((item) => {
            item.value = '';
        });
    }
    const onChangeData = (event : ChangeEvent<any>) : void => {
        const data = {
                ...Data.current,
                [event.target.id]: event.target.value
            }
        Data.current = data;
    }


    const getJob = () : void => {
        API_Get_Job('').then(res => {
            setJob(res.data);
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด',
                text: 'ไม่สามารถเชื่อมต่อฐานข้อมูลได้',
                timer: 5000,
                timerProgressBar: true,
            });
        });
    }

    const onEsc = () : void => {
        document.onkeydown = (event) => {
            event.keyCode === 27 && setToggle(false);
        }
    }

    const Check_Password = () : boolean => {
        let status = false;

        if (Data.current.password !== '') {
            if (Data.current.password.length >= 10) {
                status = true;
            }
        }

        if (status) {
            setError(preState => ({
                ...preState,
                password: false
            }));
            return true;
        } else {
            setError(preState => ({
                ...preState,
                password: true
            }));
            document.getElementById('password')?.focus();
            return false;
        }
    }

    useEffect(() => {
        onEsc();
        getJob();
    }, []);

    return (
        <>
        <button onClick={() => setToggle(!Toggle)} className="button is-link">เพิ่มสมาชิก</button>
        <div className={`modal ${Toggle ? 'is-active' : ''}`}>
            <div onClick={() => setToggle(!Toggle)} className="modal-background" />
            <div className="modal-card">
                <header className="modal-card-head">
                <p className="modal-card-title">เพิ่มสมาชิก</p>
                <button onClick={() => setToggle(!Toggle)} className="delete" aria-label="close" />
                </header>
                <section className="modal-card-body">
                    <div className="field">
                        <label className="label">ชื่อผู้ใช้งาน</label>
                        <div className="control">
                            <input onKeyPress={onEnter} onChange={onChangeData} 
                            autoFocus className={`input ${Error.username ? 'is-danger' : ''}`} type="text" id="username" placeholder="ชื่อผู้ใช้งาน" />
                        </div>
                    </div>
                    <div className="field">
                        <label className="label">รหัสผ่าน</label>
                        <div className="control">
                            <input onKeyPress={onEnter} onChange={onChangeData} 
                            className={`input ${Error.password ? 'is-danger' : ''}`} type="password" id="password" placeholder="รหัสผ่านของท่าน 10 ตัวขึ้นไป" />
                        </div>
                    </div>
                    <div className="field">
                        <label className="label">ชื่อจริง</label>
                        <div className="control">
                            <input onKeyPress={onEnter} onChange={onChangeData} 
                            className={`input ${Error.firstname ? 'is-danger' : ''}`} type="text" id="firstname" placeholder="ชื่อจริงของท่าน" />
                        </div>
                    </div>
                    <div className="field">
                        <label className="label">นามสกุล</label>
                        <div className="control">
                            <input onKeyPress={onEnter} onChange={onChangeData} 
                            className={`input ${Error.lastname ? 'is-danger' : ''}`} type="text" id="lastname" placeholder="นามสกุล" />
                        </div>
                    </div>
                    <div className="field">
                        <label className="label">เพศ</label>
                        <div className="control">
                            <select id="gender" onChange={onChangeData} defaultValue="" className={`input ${Error.gender ? 'is-danger' : ''}`}>
                                <option disabled value="">กรุณาเลือกเพศ</option>
                                <option value="0">ชาย</option>
                                <option value="1">หญิง</option>
                            </select>
                        </div>
                    </div>
                    <div className="field">
                        <label className="label">อาชีพ</label>
                        <div className="control">
                            <select id="id_job" onChange={onChangeData} defaultValue="" className={`input ${Error.id_job ? 'is-danger' : ''}`}>
                                <option disabled value="">กรุณาเลือกอาชีพ</option>
                                {
                                    Job.map((item, index) => (
                                        <option key={index} value={item['id_job']}>{item['name_job']}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>

                    <button onClick={() => onSubmitData()} className="button is-link is-fullwidth">เพิ่มสมาชิก</button>
                </section>
            </div>
        </div>

        </>
    );
}

export default Member_Add;