import React, { useEffect, KeyboardEvent, ChangeEvent, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import Swal from 'sweetalert2';
import Menu from '../../Components/Menu';
import { API_Get_Job, API_Update_Job } from '../../Utils/api';

interface ItemStruct {
    id_job: string
    name_job: string
}

const Job_Edit = () => {

    const history = useHistory();

    const param = useParams<{
        id: string
    }>();

    const Data = useRef<ItemStruct>({
        id_job: param.id,
        name_job: ''
    });

    const [Error, setError] = useState<boolean>(false);


    const onEnter = (event : KeyboardEvent<HTMLInputElement>) : void => {
        event.key === 'Enter' && onSubmitData()
    }

    const onSubmitData = () : void => {
        if (CheckEmpty()) {
            Swal.fire({
                icon: 'warning',
                title: 'คำเตือน',
                text: 'คุณต้องการที่จะแก้ไขข้อมูล ใช่ หรือ ไม่?',
                timer: 5000,
                timerProgressBar: true,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'ใช่',
                cancelButtonText: 'ไม่',
            }).then(result => {
                if (result.value) {
                    PostData();
                }
            });
        }
    }

    const CheckEmpty = () : boolean => {
        if (Data.current.name_job !== '') {
            setError(false);
            return true;
        } else {
            document.getElementById('name_job')?.focus();
            setError(true);
            return false;
        }
    }

    const PostData = () : void => {
        API_Update_Job(Data.current).then(res => {
            Swal.fire({
                icon: res.status ? 'success' : 'error',
                title: res.status ? 'สำเสร็จ' : 'เกิดข้อผิดพลาด',
                text: res.message,
                timer: 5000,
                timerProgressBar: true,
            }).then(() => {
                if (res.status) {
                    history.push('/job');
                }
            });
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด',
                text: 'ไม่สามารถเชื่อมต่อฐานข้อมูลได้',
                timer: 5000,
                timerProgressBar: true,
            });
        });
    }

    const onChangeData = (event : ChangeEvent<HTMLInputElement>) : void => {
        Data.current.name_job = event.target.value;
    }

    const Get_Data = () => {
        API_Get_Job(param.id).then(res => {
            Data.current = res.data[0];
            const job_default = document.getElementById('name_job') as HTMLInputElement;
            job_default.value = Data.current.name_job;

        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด',
                text: 'ไม่สามารถเชื่อมต่อฐานข้อมูลได้',
                timer: 5000,
                timerProgressBar: true,
            }).then(() => {
                history.push('/job');
            })
        });
    }

    useEffect(() => {
        Get_Data();
    }, []);

    return (
        <div className="is-flex" style={{gap: '10px'}}>
            <Menu />
            <div className="p-2 container">
                <h5 className="subtitle is-5 mt-3 mb-3">จัดการกับอาชีพ / แก้ไขข้อมูลอาชีพ</h5>
                <div className="card">
                    <div className="card-content">
                        <div className="field">
                            <label className="label">ชื่ออาชีพ</label>
                            <div className="control">
                                <input onKeyPress={onEnter} onChange={onChangeData} 
                                autoFocus className={`input ${Error ? 'is-danger' : ''}`} type="text" id="name_job" placeholder="ชื่ออาชีพ" />
                            </div>
                        </div>
                        <button onClick={() => onSubmitData()} className="button is-link is-fullwidth">แก้ไขข้อมูล</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Job_Edit;