import React, { useEffect, KeyboardEvent, ChangeEvent, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import Swal from 'sweetalert2';
import Menu from '../../Components/Menu';
import { API_Get_Brand, API_Get_Country, API_Update_Brand } from '../../Utils/api';

interface ItemStruct {
    id_brand: string
    id_country: string
    name_brand: string
    website: string
    address: string
    tel: string
    email: string
}

const Brand_Edit = () => {

    const param = useParams<{
        id: string
    }>();

    const [Data, setData] = useState<ItemStruct>({
        id_brand: param.id,
        id_country: '',
        name_brand: '',
        website: '',
        address: '',
        tel: '',
        email: '',
    });

    const [Country, setCountry] = useState([]);
    
    const [Error, setError] = useState({
        country: false,
        name_brand: false,
        website: false,
        tel: false,
        email: false,
        address: false
    });

    const history = useHistory();

    const onEnter = (event : KeyboardEvent<HTMLInputElement>) : void => {
        event.key === 'Enter' && onSubmitData()
    }

    const onSubmitData = () : void => {
        if (
            CheckEmpty(Data.id_country, 'country') &&
            CheckEmpty(Data.name_brand, 'name_brand') &&
            CheckEmpty(Data.website, 'web') &&
            CheckEmpty(Data.tel, 'tel') &&
            CheckEmpty(Data.email, 'email') &&
            CheckEmpty(Data.address, 'address')
        ) {
            Swal.fire({
                icon: 'warning',
                title: 'คำเตือน',
                text: 'คุณต้องการที่จะแก้ไขข้อมูล ใช่ หรือ ไม่?',
                timer: 5000,
                timerProgressBar: true,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'ใช่',
                cancelButtonText: 'ไม่',
            }).then(result => {
                if (result.value) {
                        PostData();
                }
            });
        }
    }

    const PostData = () : void => {
        API_Update_Brand(Data).then(res => {
            Swal.fire({
                icon: res.status ? 'success' : 'error',
                title: res.status ? 'สำเสร็จ' : 'เกิดข้อผิดพลาด',
                text: res.message,
                timer: 5000,
                timerProgressBar: true,
            }).then(() => {
                if (res.status) {
                    history.push('/brand');
                }
            });
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด',
                text: 'ไม่สามารถเชื่อมต่อฐานข้อมูลได้',
                timer: 5000,
                timerProgressBar: true,
            });
        });
    }
    const CheckEmpty = (value: string, id : string) : boolean => {
        if (value !== '') {
            setError(preState => ({
                ...preState,
                [id]: false
            }));
            return true;
        } else {
            document.getElementById(id)?.focus();
            setError(preState => ({
                ...preState,
                [id]: true
            }));
            return false;
        }
    }

    const onChangeData = (event : ChangeEvent<any>) : void => {
        setData(preState => ({
            ...preState,
            [event.target.id]: event.target.value
        }));
    }

    const Get_Data = () : void => {
        API_Get_Brand(param.id).then(res => {
            setData(res.data[0]);
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด',
                text: 'ไม่สามารถเชื่อมต่อฐานข้อมูลได้',
                timer: 5000,
                timerProgressBar: true,
            });
        });
    }

    const Get_Country = () : void => {
        API_Get_Country('').then(res => {
            setCountry(res.data);
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด',
                text: 'ไม่สามารถเชื่อมต่อฐานข้อมูลได้',
                timer: 5000,
                timerProgressBar: true,
            });
        });
    }

    useEffect(() => {
        Get_Country();
        Get_Data();
    }, []);

    return (
        <div className="is-flex" style={{gap: '10px'}}>
            <Menu />
            <div className="p-2 container">
                <h5 className="subtitle is-5 mt-3 mb-3">จัดการกับแบรนด์ / แก้ไขข้อมูลแบรนด์</h5>
                <div className="card">
                    <div className="card-content">
                        <div className="field">
                            <label className="label">ประเทศที่วางจำหน่าย</label>
                            <div className="control">
                                <select value={Data.id_country} defaultValue="" id="id_country" onChange={onChangeData} className={`input ${Error.country ? 'is-danger' : ''}`}>
                                    <option disabled value="">กรุณาเลือกประเทศ</option>
                                    {
                                        Country.map((item, index) => (
                                            <option key={index} value={item['id_country']}>{item['name_country']}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">ชื่อแบรนด์</label>
                            <div className="control">
                                <input value={Data.name_brand} onKeyPress={onEnter} onChange={onChangeData} 
                                autoFocus className={`input ${Error.name_brand ? 'is-danger' : ''}`} type="text" id="name_brand" placeholder="ชื่อแบรนด์" />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">เว็บไซต์</label>
                            <div className="control">
                                <input value={Data.website} onKeyPress={onEnter} onChange={onChangeData} 
                                className={`input ${Error.website ? 'is-danger' : ''}`} type="text" id="website" placeholder="เว็บไซต์" />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">เบอร์โทร</label>
                            <div className="control">
                                <input value={Data.tel} onKeyPress={onEnter} onChange={onChangeData} 
                                className={`input ${Error.tel ? 'is-danger' : ''}`} type="text" id="tel" placeholder="เบอร์โทร" />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">อีเมล์</label>
                            <div className="control">
                                <input value={Data.email} onKeyPress={onEnter} onChange={onChangeData} 
                                className={`input ${Error.email ? 'is-danger' : ''}`} type="email" id="email" placeholder="อีเมล์" />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">ที่ตั้ง</label>
                            <div className="control">
                                <textarea value={Data.address} onChange={onChangeData} className={`input ${Error.address ? 'is-danger' : ''}`} placeholder="ที่ตั้งของบริษัท" id="address"></textarea>
                            </div>
                        </div>
                        <button onClick={() => onSubmitData()} className="button is-link is-fullwidth">แก้ไขข้อมูล</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Brand_Edit;