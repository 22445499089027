import React, { useEffect, KeyboardEvent, ChangeEvent, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import Swal from 'sweetalert2';
import Menu from '../../Components/Menu';
import { API_Get_Country, API_Update_Country } from '../../Utils/api';

interface ItemStruct {
    id_country: string
    name_country: string
}

const Country_Edit = () => {

    const history = useHistory();

    const param = useParams<{
        id: string
    }>();

    const Data = useRef<ItemStruct>({
        id_country: param.id,
        name_country: ''
    });
    
    const [Error, setError] = useState<boolean>(false);


    const onEnter = (event : KeyboardEvent<HTMLInputElement>) : void => {
        event.key === 'Enter' && onSubmitData()
    }

    const onSubmitData = () : void => {
        if (CheckEmpty()) {
            Swal.fire({
                icon: 'warning',
                title: 'คำเตือน',
                text: 'คุณต้องการที่จะแก้ไขข้อมูล ใช่ หรือ ไม่?',
                timer: 5000,
                timerProgressBar: true,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'ใช่',
                cancelButtonText: 'ไม่',
            }).then(result => {
                if (result.value) {
                    PostData();
                }
            });
        }
    }

    const PostData = () : void => {
        API_Update_Country(Data.current).then(res => {
            Swal.fire({
                icon: res.status ? 'success' : 'error',
                title: res.status ? 'สำเสร็จ' : 'เกิดข้อผิดพลาด',
                text: res.message,
                timer: 5000,
                timerProgressBar: true,
            }).then(() => {
                if (res.status) {
                    history.push('/country');
                }
            });
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด',
                text: 'ไม่สามารถเชื่อมต่อฐานข้อมูลได้',
                timer: 5000,
                timerProgressBar: true,
            });
        });
    }

    const CheckEmpty = () : boolean => {
        if (Data.current.name_country !== '') {
            setError(false);
            return true;
        } else {
            document.getElementById('name_country')?.focus();
            setError(true);
            return false;
        }
    }

    const onChangeData = (event : ChangeEvent<HTMLInputElement>) : void => {
        Data.current.name_country = event.target.value;
    }

    const Get_Data = () => {
        API_Get_Country(param.id).then(res => {
            Data.current = res.data[0];
            const country_default = document.getElementById('name_country') as HTMLInputElement;
            country_default.value = Data.current.name_country;
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด',
                text: 'ไม่สามารถเชื่อมต่อฐานข้อมูลได้',
                timer: 5000,
                timerProgressBar: true,
            }).then(() => {
                history.push('/country');
            })
        });
    }

    useEffect(() => {
        Get_Data();
    });

    return (
        <div className="is-flex" style={{gap: '10px'}}>
            <Menu />
            <div className="p-2 container">
                <h5 className="subtitle is-5 mt-3 mb-3">จัดการกับประเทศ / แก้ไขข้อมูลประเทศ</h5>
                <div className="card">
                    <div className="card-content">
                        <div className="field">
                            <label className="label">ชื่อประเทศ</label>
                            <div className="control">
                                <input onKeyPress={onEnter} onChange={onChangeData} id="name_country"
                                autoFocus className={`input ${Error ? 'is-danger' : ''}`} type="text" placeholder="ชื่อประเทศ" />
                            </div>
                        </div>
                        <button onClick={() => onSubmitData()} className="button is-link is-fullwidth">แก้ไขข้อมูล</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Country_Edit;