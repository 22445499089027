import React, { useContext, useEffect } from 'react';
import { Route, useHistory } from 'react-router';
import '../loading.css';
import { API_Me } from '../Utils/api';
import { AuthContext } from '../Utils/auth-context';
import Brand_Page from './Brand';
import Brand_Edit from './Brand/edit';
import Brand_Image from './Brand/image';
import Category_Page from './Category';
import Category_Edit from './Category/edit';
import Country_Page from './Country';
import Country_Edit from './Country/edit';
import Customer_Page from './Customer';
import Customer_Edit from './Customer/edit';
import Customer_Password from './Customer/password';
import Home from './Home';
import Job_Page from './Job';
import Job_Edit from './Job/edit';
import Kind_Page from './Kind/';
import Kind_Edit from './Kind/edit';
import Member_Page from './Member';
import Member_Edit from './Member/edit';
import Member_Password from './Member/password';
import Product_Page from './Product';
import Update_Page from './Update';
import Work_Page from './Work';
import Work_Edit from './Work/edit';

const Loading = () => {

    const context = useContext(AuthContext);

    const history = useHistory();

    const Clear = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');
        history.push('/login');
    }

    useEffect(() => {
        if (localStorage.hasOwnProperty('token')) {
            API_Me().then(res => {
                try {
                    if (res.status === 200) {
                        if (res.data.status) {
                            context.auth[1](true);
                        }
                    } else {
                        if (localStorage.hasOwnProperty('refresh_token')) {
                            window.location.reload();
                        } else {
                            Clear();
                        }
                    }
                } catch {
                    Clear();
                }
            })
        } else {
            history.push('/login');
        }
    }, []);


    if (!context.auth[0]) {
        return (
            <div className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center" style={{height: '100vh', gap: '20px'}}>
                <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                <h4 className="title is-4">รอสักครู่...</h4>
            </div>
        );
    }

    return (
        <>
        <Route exact path="/" component={Home} />
        <Route exact path="/product" component={Product_Page} />
        <Route exact path="/member" component={Member_Page} />
        <Route exact path="/member/password/:id" component={Member_Password} />
        <Route exact path="/member/edit/:id" component={Member_Edit} />
        <Route exact path="/work" component={Work_Page} />
        <Route exact path="/work/edit/:id" component={Work_Edit} />
        <Route exact path="/category" component={Category_Page} />
        <Route exact path="/category/edit/:id" component={Category_Edit} />
        <Route exact path="/kind" component={Kind_Page} />
        <Route exact path="/kind/edit/:id" component={Kind_Edit} />
        <Route exact path="/job" component={Job_Page} />
        <Route exact path="/job/edit/:id" component={Job_Edit} />
        <Route exact path="/brand" component={Brand_Page} />
        <Route exact path="/brand/edit/:id" component={Brand_Edit} />
        <Route exact path="/brand/image/:id" component={Brand_Image} />
        <Route exact path="/country" component={Country_Page} />
        <Route exact path="/country/edit/:id" component={Country_Edit} />
        <Route exact path="/customer" component={Customer_Page} />
        <Route exact path="/customer/edit/:id" component={Customer_Edit} />
        <Route exact path="/customer/password/:id" component={Customer_Password} />
        <Route exact path="/update" component={Update_Page} />
        </>
    )
}

export default Loading;