import React, { useEffect, KeyboardEvent, ChangeEvent, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import Swal from 'sweetalert2';
import Menu from '../../Components/Menu';
import { API_Get_Brand, API_Get_Customer, API_Update_Customer } from '../../Utils/api';

interface ItemStruct {
    id_customer: string
    id_brand: string
    username: string
}

const Customer_Edit = () => {

    const param = useParams<{
        id: string
    }>();

    const [Data, setData] = useState<ItemStruct>({
        id_customer: param.id,
        id_brand: '',
        username: '',
    });

    const history = useHistory();

    const [Brand, setBrand] = useState([]);

    const [Error, setError] = useState({
        id_brand: false as boolean,
        username: false as boolean,
    });


    const onEnter = (event : KeyboardEvent<HTMLInputElement>) : void => {
        event.key === 'Enter' && onSubmitData()
    }

    const onSubmitData = () : void => {
        if (
            CheckEmpty(Data.id_brand, 'id_brand') &&
            CheckEmpty(Data.username, 'username') 
        ) {
            Swal.fire({
                icon: 'warning',
                title: 'คำเตือน',
                text: 'คุณต้องการที่จะแก้ไขข้อมูล ใช่ หรือ ไม่?',
                timer: 5000,
                timerProgressBar: true,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'ใช่',
                cancelButtonText: 'ไม่',
            }).then(result => {
                if (result.value) {
                    PostData();
                }
            });
        }
    }

    const PostData = () : void => {
        API_Update_Customer(Data).then(res => {
            Swal.fire({
                icon: res.status ? 'success' : 'error',
                title: res.status ? 'สำเสร็จ' : 'เกิดข้อผิดพลาด',
                text: res.message,
                timer: 5000,
                timerProgressBar: true,
            }).then(() => {
                if (res.status) {
                    history.push('/customer');
                }
            });
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด',
                text: 'ไม่สามารถเชื่อมต่อฐานข้อมูลได้',
                timer: 5000,
                timerProgressBar: true,
            });
        });
    }

    const onChangeData = (event : ChangeEvent<any>) : void => {
        setData(preState => ({
            ...preState,
            [event.target.id]: event.target.value
        }))
    }

    const CheckEmpty = (value : string, id : string) : boolean => {
        if (value !== '') {
            setError(preState => ({
                ...preState,
                [id]: false
            }));
            return true;
        } else {
            document.getElementById(id)?.focus();
            setError(preState => ({
                ...preState,
                [id]: true
            }));
            return false;
        }
    }
    
    const Get_Data = () => {
        API_Get_Customer(param.id).then(res => {
            setData(res.data[0]);
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด',
                text: 'ไม่สามารถเชื่อมต่อฐานข้อมูลได้',
                timer: 5000,
                timerProgressBar: true,
            }).then(() => {
                history.push('/customer');
            })
        });
    }

    const Get_Brand = () => {
        API_Get_Brand('').then(res => {
            setBrand(res.data);
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด',
                text: 'ไม่สามารถเชื่อมต่อฐานข้อมูลได้',
                timer: 5000,
                timerProgressBar: true,
            }).then(() => {
                history.push('/customer');
            })
        });
    }

    useEffect(() => {
        Get_Brand();
        Get_Data();
    }, []);



    return (
        <div className="is-flex" style={{gap: '10px'}}>
            <Menu />
            <div className="p-2 container">
                <h5 className="subtitle is-5 mt-3 mb-3">จัดการกับลูกค้า / แก้ไขข้อมูลลูกค้า</h5>
                <div className="card">
                    <div className="card-content">
                        <div className="field">
                            <label className="label">แบรนด์ที่ดูแล</label>
                            <div className="control">
                                <select value={Data.id_brand} defaultValue="" id="id_brand" onChange={onChangeData} className={`input ${Error.id_brand ? 'is-danger' : ''}`}>
                                    <option disabled value="">กรุณาเลือกแบรนด์</option>
                                    {
                                        Brand.map((item, index) => (
                                            <option key={index} value={item['id_brand']}>{item['name_brand']}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">ชื่อผู้ใช้งาน</label>
                            <div className="control">
                                <input value={Data.username} onKeyPress={onEnter} id="username" onChange={onChangeData} 
                                autoFocus className={`input ${Error.username ? 'is-danger': ''}`} type="text" placeholder="ชื่อผู้ใช้งาน" />
                            </div>
                        </div>
                        <button onClick={() => onSubmitData()} className="button is-link is-fullwidth">แก้ไขข้อมูล</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Customer_Edit;